import { makeStyles } from "@mui/styles";
// import { opacity2hex } from "utils/miscUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  smallField: {
    width: 120,
  },
  field: {
    width: 220,
  },
  button: {
    width: "150px",
    height: 45,
  },
  submitButton: {
    marginTop: 20,
    width: "150px",
    height: 45,
  },
  savecancel: {
    marginTop: 20,
    width: "100px",
    height: 40,
  },
  buttonContainer: {
    display: "flex",
    gap: 10,
  },
  firstFormContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 4,
    margin: "0px 0px 10px 0px",
  },
  secondFormContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 25,
    margin: "10px 0px 20px 0px",
  },
  requestContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: 15,
  },
}));

export default useStyles;

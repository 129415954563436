function clubTableColumns() {
  return [
    {
      id: "name",
      label: `Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "abbreviationName",
      label: `Abbreviation Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "",
    },
    {
      id: "textColor",
      label: `Text Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "",
    },
    {
      id: "backgroundColor",
      label: `Background Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "",
    },
    {
      id: "jerseyYokeColor",
      label: `Jersey Yoke Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "",
    },
    {
      id: "jerseyFrontColor",
      label: `Jersey Front Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "jerseySleevesColor",
      label: `Jersey Sleeves Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "jerseyCollarColor",
      label: `Jersey Collar Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "jerseyCollarType",
      label: `Jersey Collar`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "jerseySize",
      label: `Jersey Size`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    { id: "actions", label: "Actions" },
  ];
}

function playerCardsListColumn() {
  return [
    {
      id: "fullName",
      label: `Full Name`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "club",
      label: `Club`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "league",
      label: `League`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "preferredPosition",
      label: `Position`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Preferred Position",
    },
    {
      id: "nationality",
      label: `Country`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Preferred Position",
    },
    {
      id: "attackRating",
      label: `ATT`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Attack Rating",
    },
    {
      id: "defenseRating",
      label: `DEF`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Defense Rating",
    },
    {
      id: "creativityRating",
      label: `CRE`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Creativity Rating",
    },
    {
      id: "overallRating",
      label: `OVR`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Overall Rating",
    },
    {
      id: "rarity",
      label: `Rarity`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
    },
    { id: "thumbnail", label: "Preview" },
    { id: "actions", label: "Actions" },
  ];
}

export { clubTableColumns, playerCardsListColumn };

import React, { useState } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { InputShell } from "./FormInputShell";

const SearchableInput = ({
  name,
  selected,
  options,
  label,
  placeholder,
  onInputChange,
  onOptionSelect,
  customStyle,
  loading,
  fetchMore,
  hasMore,
  isMultiSelect = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(selected ?? null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (onInputChange) {
      onInputChange(newInputValue);
    }
  };

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
    if (onOptionSelect) {
      onOptionSelect({ target: { name: name, value: newValue } });
    }
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    const bottomReached =
      listboxNode.scrollHeight - listboxNode.scrollTop <=
      listboxNode.clientHeight + 10;

    if (bottomReached && hasMore && !isFetchingMore) {
      fetchMore().finally(() => {
        setIsFetchingMore(false);
      });
    }
  };

  const isOptionEqualToValue = (option, value) => {
    return option.label === value.label;
  };

  return (
    <Autocomplete
      multiple={isMultiSelect}
      size={props.size ?? "small"}
      value={selectedOption}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleOptionChange}
      options={options}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={(options, state) =>
        options.filter((option) =>
          option.label?.toLowerCase().includes(state.inputValue?.toLowerCase())
        )
      }
      noOptionsText="No data Found"
      ListboxProps={{
        onScroll: handleScroll,
      }}
      loading={loading || isFetchingMore}
      renderInput={(params) => {
        return (
          <InputShell label={label} name={props.name}>
            <TextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
              style={{ ...customStyle }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          </InputShell>
        );
      }}
      {...props}
    />
  );
};

export default SearchableInput;

const defaultCardDetails = {
  fullName: "Peter Horns",
  commonName: "P. Horns",
  displayName: "Peter Horns",
  displayNameAbb: "Horns",
  overallRating: "43",
  positionName:"",
  position: "",
  cardType: "Bronze",
  clubTextColor: "#DD0000",
  clubBGColor: "#0000FF",
  clubName: "",
  clubAbbrName: "",
  country: "France",
  attackRating: 50,
  defenceRating: 50,
  creativityRating: 50,
  playerFaceImage: "images/playerCard/player1.png",
  collarType: "V",
  face: "Face 1",
  emblem: "Emblem 1",
  jerseySize: "S",
  textColor: "#000000",
  positionBGColor: "#FF5733",
  jerseyBackColor: "#FFFFFF",
  jerseyYokeColor: "#DD0000",
  jerseyFrontColor: "#00FF00",
  jerseySleevesColor: "#0000FF",
  jerseyCollarColor: "#FFFFFF",
};

const positionColorValues = {
  GK: { positionBGColor: "#04DBFA", textColor: "#000000" },
  LB: { positionBGColor: "#04DBFA", textColor: "#000000" },
  CB: { positionBGColor: "#04DBFA", textColor: "#000000" },
  RB: { positionBGColor: "#04DBFA", textColor: "#000000" },
  LM: { positionBGColor: "#FFFF00", textColor: "#000000" },
  CM: { positionBGColor: "#FFFF00", textColor: "#000000" },
  RM: { positionBGColor: "#FFFF00", textColor: "#000000" },
  ST: { positionBGColor: "#FFA500", textColor: "#FFFFFF" },
};

const BoosterSlotOptions = [
  {
    id: "Boost_All_05_5",
    label: "Boost_All_05_5",
  },
  {
    id: "Boost_Cre_05_3",
    label: "Boost_Cre_05_3",
  },
  {
    id: "Boost_Def_05_3",
    label: "Boost_Def_05_3",
  },
  {
    id: "Boost_Atk_05_3",
    label: "Boost_Atk_05_3",
  },
];

export { defaultCardDetails, positionColorValues, BoosterSlotOptions };

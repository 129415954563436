/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ContentShell from "components/ContentShell";
import ColorPicker from "components/ColorPicker";
import { sendMessageToCanvas } from "utils/miscUtils";
import PlayerCardCanvas from "components/PlayerCardCanvas";
import { useSnackbar } from "hooks/useSnackbar";
import {
  updatePosition,
  getInfoPosition,
  createPosition,
} from "services/playerCardService";
import { useLocation } from "react-router-dom";
import { SimpleTextInput } from "components/FormTextInput";
import { defaultCardDetails } from "config/playerCardDetails";

const useStyles = makeStyles((theme) => ({
  submitButton: {
    width: 230,
    backgroundColor: "#1D1030",
    color: "white",
    fontWeight: "bold",
    marginTop: 10,
  },
  playerPositionEditor: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    gap: "25px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
      gap: 25,
    },
    [theme.breakpoints.up("sm")]: {
      gap: 30,
    },
  },
}));

const EditPosition = () => {
  const styles = useStyles();
  const location = useLocation();
  const id = location.pathname.split("/").slice(-1)[0];
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [playerDetails, setPlayerDetails] = useState({
    ...defaultCardDetails,
    positionName: "",
    position: "",
  });
  const [updatedPositions] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getInfoPosition(id);
      const { name, abbreviationName, textColor, backgroundColor } =
        response.data;

      let _data = {
        ...playerDetails,
        positionName: name,
        position: abbreviationName,
        textColor: textColor ?? playerDetails.textColor,
        positionBGColor: backgroundColor ?? playerDetails.positionBGColor,
      };

      setPlayerDetails((prevDetails) => {
        return { ...prevDetails, ..._data };
      });
      setTimeout(() => sendToCanvas("INIT", _data), 500);
    } catch (error) {
      console.error("Error fetching position details:", error);
    } finally {
      setLoading(false);
    }
  };

  const sendToCanvas = (type, data) => {
    const iframe = document.getElementById("playerCard");
    if (iframe) {
      sendMessageToCanvas(iframe, {
        message: "UPDATE_PLAYER",
        type: type === "INIT" ? "INIT" : type,
        data: data,
      });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setPlayerDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "position") {
      const selectedPosition = updatedPositions.find(
        (item) => item.id === value
      );
      if (selectedPosition) {
        setPlayerDetails((prev) => ({
          ...prev,
          textColor: selectedPosition.textColor,
          positionBGColor: selectedPosition.positionBGColor,
        }));
      }
    }

    sendToCanvas(name, { value });
  };

  const submit = async (e) => {
    const positionData = {
      abbreviationName: playerDetails.position,
      name: playerDetails.positionName,
      textColor: playerDetails.textColor,
      backgroundColor: playerDetails.positionBGColor,
    };

    let response;

    try {
      if (id !== "create") {
        response = await updatePosition(positionData, id);
      } else {
        response = await createPosition(positionData);
      }

      showSnackbar(response.message, "success");
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  useEffect(() => {
    if (id !== "create") fetchData();
  }, []);

  return (
    <ContentShell
      showBackButton
      loading={loading}
      title={id !== "create" ? "Edit Position" : "Create Position"}
    >
      <Box className={styles.playerPositionEditor}>
        <Box>
          <SimpleTextInput
            type="text"
            label="Position Name"
            name="positionName"
            value={playerDetails.positionName}
            onChange={onChange}
          />
          <br />
          <PlayerCardCanvas
            playerDetails={playerDetails}
            sendToCanvas={sendToCanvas}
          />
        </Box>
        <Box>
          <SimpleTextInput
            type="text"
            label="Position Abbreviation Name"
            name="position"
            value={playerDetails.position}
            onChange={onChange}
          />
          <ColorPicker
            label={"Position Text Color"}
            name="textColor"
            value={playerDetails.textColor}
            onChange={onChange}
            customStyle={{ width: 230, marginTop: 7 }}
          />
          <ColorPicker
            label={"Position Bg Color"}
            name="positionBGColor"
            value={playerDetails.positionBGColor}
            onChange={onChange}
            customStyle={{ width: 230, marginTop: 7 }}
          />
          <Button
            type="submit"
            variant="contained"
            className={styles.submitButton}
            onClick={submit}
          >
            {id !== "create" ? "Update Position" : "Create Position"}
          </Button>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default EditPosition;

import moment from "moment";
import { opacities } from "./hex_opacity";
import { round } from "mathjs";

const gameCodes = ["hit6", "mpCric", "cricket2023"];

const days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const toIST = (date) => moment(date).tz("Asia/Kolkata");

const formatUtcDate = (date, format) => {
  const _format = format ?? "DD-MM-YYYY HH:mm:ss";
  return moment.utc(date).format(_format);
};

const formatDate = (date, format = "DD - MM - YYYY") => {
  if (format === "utc") return moment.utc(date).format();

  return moment(date).format(format);
};

const formatTime = (date, format = "hh:mm") => {
  if (format === "utc") return moment.utc(date).format();

  return moment(date).format(format);
};

const startOfWeek = () => {
  const moment = require("moment");
  const currentDate = moment();
  return new Date(currentDate.startOf("week"));
};

const startOfMonth = () => {
  let date = new Date();

  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const sortArray = (filteredlist, orderBy, column) => {
  const key = column?.id || "";
  if (!key) return filteredlist;

  if (orderBy === "asc") {
    switch (column?.type) {
      case "number":
      case "string":
        return filteredlist.sort((a, b) =>
          a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
        );
      case "date":
        return filteredlist.sort(function (a, b) {
          return (
            new Date(b[key.replace("Text", "")]) -
            new Date(a[key.replace("Text", "")])
          );
        });
      default:
        return filteredlist;
    }
  } else {
    switch (column?.type) {
      case "string":
      case "number":
        return filteredlist.sort((a, b) =>
          a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0
        );
      case "date":
        return filteredlist
          .sort(function (a, b) {
            return (
              new Date(b[key.replace("Text", "")]) -
              new Date(a[key.replace("Text", "")])
            );
          })
          .reverse();
      default:
        return filteredlist;
    }
  }
};

const JSONSearch = (list = [], keys = [], value) => {
  const searchValue = value.toLowerCase();
  return list.filter((data) =>
    Object.keys(data).some(
      (key) =>
        keys.includes(key) &&
        data[key] &&
        data[key].toString().toLowerCase().includes(searchValue)
    )
  );
};

const setNumberFormat = (number, decimal) => {
  if (!number || isNaN(number) || isNaN(decimal) || decimal < 0) {
    return number?.toString()?.split(".")[0] + "." + `000000`.slice(0, decimal);
  }

  let numString = number?.toString();
  let parts = numString?.split(".");

  if (parts?.length > 1) {
    numString = parts[0] + "." + `${parts[1]}000000`.slice(0, decimal);
  } else numString = Number(parts[0]).toFixed(decimal);

  return numString;
};

const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const strToSankeCase = (str) => {
  if (!str) return "";

  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("_");
};

const getFilteredPayload = ({ initial = {}, curr = {} }) =>
  Object.keys(initial).reduce((acc, key) => {
    if (curr.hasOwnProperty(key)) {
      acc[key] = curr[key];
    }
    return acc;
  }, {});

const areArraysEqual = (array1 = [], array2 = []) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const frequencyCounter = {};

  for (let element of array1) {
    frequencyCounter[element] = (frequencyCounter[element] || 0) + 1;
  }

  for (let element of array2) {
    if (!frequencyCounter[element]) {
      return false;
    }
    frequencyCounter[element]--;
  }

  return true;
};

const commaSeparated = (num) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const convertToNumber = (num = "0", isCommaSep = false) => {
  return isCommaSep ? Number(num?.toString().replace(/,/g, "")) : Number(num);
};

const convertToMillions = (number) => {
  if (Math.abs(number) >= 1e4) {
    return (number / 1e6).toFixed(2) + "M";
  }
  return setNumberFormat(number, 2);
};

function getCurrencySymbol(currencyCode, locale = "en-US") {
  if (["XPS", "xps"].includes(currencyCode)) return currencyCode;
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .formatToParts(0)
    .find((part) => part.type === "currency").value;
}

function sendMessageToCanvas(loadedIframe, data) {
  if (loadedIframe && loadedIframe?.contentWindow) {
    loadedIframe?.contentWindow?.gameExtension(data, "*");
  }
}

function opacity2hex(alpha) {
  let hex = opacities[`${alpha}`];

  if (!hex) {
    return "FF";
  }

  return hex;
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Round a value towards the nearest integer
 * @param value
 * @returns
 */
const roundOff = (value) => {
  return Math.trunc(round(value));
};

const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

const deepCopy = (data) => JSON.parse(JSON.stringify(data));

function blobToBinary(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function () {
      const arrayBuffer = reader.result;
      resolve(arrayBuffer);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(blob);
  });
}

async function getVersion() {
  try {
    const response = await fetch(`/version.txt`);
    // Check if the response is okay and ensure it's not the `index.html`
    if (!response.ok || !response.headers.get("content-type")?.includes("text/plain")) {
      throw new Error("version.txt not found or is incorrect");
    }

    const version = await response.text();
    return version.trim();
  } catch (error) {
    console.log("Error loading version:", error);
    return ""; // or another default string
  }
}

export {
  deepCopy,
  delay,
  capitalize,
  formatUtcDate,
  formatDate,
  formatTime,
  startOfWeek,
  startOfMonth,
  sortArray,
  JSONSearch,
  setNumberFormat,
  randomNumber,
  strToSankeCase,
  days,
  months,
  gameCodes,
  getFilteredPayload,
  areArraysEqual,
  commaSeparated,
  convertToNumber,
  toIST,
  convertToMillions,
  getCurrencySymbol,
  sendMessageToCanvas,
  opacity2hex,
  roundOff,
  blobToBinary,
  getVersion
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  colorPickerContainer: {
    display: "flex",
    background: "#3d3d3d20",
    alignItems: "center",
    padding: "6px 5px 6px 6px",
    borderRadius: 12,
  },
  labelStyle: {
    color: "black",
    alignSelf: "center",
    fontWeight: 600,
    fontSize: 14,
    marginLeft: 10,
  },
  picker: {
    height: 30,
    width: 30,
    borderRadius: 15,
  },
  chromePickerEditor: {
    position: "absolute",
    zIndex: 99,
  },
}));

const ColorPicker = ({ name, label, value, onChange, customStyle }) => {
  const styles = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible((prev) => !prev);

  const ref = useRef();

  const handleChange = (picked) => {
    // const { a } = picked.rgb;
    // let color = a !== 1 ? opacity2hex(a) : "FF";
    onChange({
      target: {
        name: name,
        value: picked.hex, // color === "FF" ? picked.hex : `${picked.hex}${color}`,
      },
    });
  };

  useEffect(() => {
    const listener = document.body.addEventListener("click", (event) => {
      if (ref.current && !event.composedPath().includes(ref.current)) {
        if (!isVisible) setIsVisible(false);
      }
    });

    return () => listener;
  }, []);

  return (
    <div ref={ref} className={styles.root} style={{ ...customStyle }}>
      <div className={styles.colorPickerContainer}>
        <div
          onClick={toggle}
          className={styles.picker}
          style={{ background: value }}
        ></div>
        <div className={styles.labelStyle} onClick={toggle}>
          {label}
        </div>
      </div>
      {isVisible && (
        <div className={styles.chromePickerEditor}>
          <ChromePicker
            color={value}
            disableAlpha
            onChange={handleChange}
            // onChangeComplete={handleChange}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Typography } from "@mui/material";
import ContentShell from "components/ContentShell";
import { SimpleSelectInput } from "components/FormSelectInput";
import { SimpleTextInput } from "components/FormTextInput";
import { useSnackbar } from "hooks/useSnackbar";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import configData from "config/config.json";
import useStyles from "styles/pack/packEditor";
import { SimpleMultiSelectInput } from "components/FormMultiSelectInput";
import {
  createPack2,
  getPackImages,
  packInfo,
  updatePack2,
  uploadPackImage,
} from "services/packServices";
import {
  AddAndDelete,
  BoosterSlot,
  buildLabel,
  NamedPlayerSlot,
  PlayerPoolSlot,
  PlayerSlot,
  validateNumericFields,
} from "./Slots";
import { deepCopy } from "utils/miscUtils";
import sessionHelper from "services/sessionHelper";
import FilePicker from "components/FilePicker";

const PlayerAndBooster = ["Player", "Booster"];

const boosterProbability = {
  boosterId: "",
  probability: 100,
};

const dropProbability = {
  ovrMin: 1,
  ovrMax: 100,
  probability: 100,
};

const slotTemplate = {
  type: "",
  dropProbabilities: [],
  boosterProbabilities: [],
  playerCard: {},
  amount: 0,
  playerPool: [],
};

let packTemplate = {
  name: "",
  type: [],
  summary: "",
  isDisabled: false,
  price: 0,
  slots: [slotTemplate],
  isFTUPack: false,
  fileType: "",
  packPredefinedImageType: "",
};

const modify = (slot) => {
  const { type } = slot;
  if (type === "Player") {
  }
  switch (slot.type) {
    case "Player":
      return {
        dropProbabilities: slot.dropProbabilities,
      };
    case "NamedPlayer":
      return {
        playerCard: slot.playerCard?.id,
      };
    case "Currency":
      return {
        amount: slot.amount,
      };
    case "Booster":
      return {
        boosterProbabilities: slot.boosterProbabilities?.map((bp) => {
          return {
            probability: bp?.probability,
            boosterId: bp?.boosterId?.id ?? bp?.boosterId,
          };
        }),
      };
    case "PlayerPool":
      return {
        playerPool: slot.playerPool.map((item) => item?.id),
      };
    default:
      return slot;
  }
};

const PackEditor = () => {
  const styles = useStyles();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();
  const [packDetails, setPackDetails] = useState(packTemplate);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    slotCount: 1,
    packTypeOptions: [
      { id: "Reward", label: "Reward" },
      { id: "Store", label: "Store" },
    ],
    operatorOptions: Array.from({ length: 5 }, (v, k) => ({
      id: "id_" + (k + 1),
      label: "Operator_" + (k + 1),
    })),
    noOfSlotOptions: Array.from({ length: 30 }, (v, k) => ({
      id: k + 1,
      label: k + 1,
    })),
    slotTypeOptions: [
      { id: "Player", label: "Player" },
      { id: "NamedPlayer", label: "Named Player" },
      { id: "Currency", label: "Currency" },
      { id: "Booster", label: "Booster" },
      { id: "PlayerPool", label: "Player Pool" },
    ],
    images: [],
    selectedPackImage: null,
    defaultPlayerCards: [],
    defaultPlayerCardOptions: [],
  });

  const getSlotTypeAndName = (slotIndex) => {
    let returnData = {
      type: packDetails.slots[slotIndex].type,
      name: "",
    };

    const { type } = returnData;

    if (type === "Player") returnData.name = "dropProbabilities";
    else if (type === "Booster") returnData.name = "boosterProbabilities";

    return returnData;
  };

  const cancel = () => navigate(configData.routes.packs.packs);

  const submit = async () => {
    try {
      setLoading(true);
      let resp;

      let pack = deepCopy(packDetails);
      // delete unnecessary data from pack for now
      delete pack?.playerDetails;
      delete pack?.imageUrl;

      // verify data first
      const valitions = validateNumericFields(packDetails.slots);
      if (!valitions.valid) {
        valitions.errors.map((err) => showSnackbar(err.error));
        setLoading(false);
        return;
      }

      let _slots = pack.slots.map((slot) => {
        let slotData = modify(slot);
        return {
          type: slot.type,
          ...slotData,
        };
      });

      if (options.selectedPackImage) {
        pack.fileType = "image/png";
        delete pack?.packPredefinedImageType;
      }

      if (id !== "create") {
        resp = await updatePack2({ ...pack, slots: _slots });
      } else {
        resp = await createPack2({ ...pack, slots: _slots });
      }

      let uploadResp;

      if (resp?.data?.fileUploadUrl) {
        uploadResp = await upload(resp?.data?.fileUploadUrl);
      }

      setOptions((st) => ({ ...st, selectedPackImage: null }));
      showSnackbar(resp?.message, "success");
      setLoading(false);

      if (uploadResp?.status === 200) {
        navigate(configData.routes.packs.packs);
      }
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const updateSlotCount = (e) => {
    setLoading(true);
    const _value = Number(e.target.value);
    let pack = { ...packDetails };

    if (_value > options.slotCount) {
      pack.slots = [
        ...pack.slots,
        ...Array.from(
          { length: _value - options.slotCount },
          (_, k) => slotTemplate
        ),
      ];
    } else {
      pack.slots = pack.slots.slice(0, _value);
    }

    setOptions((st) => ({ ...st, slotCount: _value }));
    setPackDetails(pack);

    setLoading(false);
  };

  const onChange = (e) => {
    const { name, value } = e?.target;
    let data = {
      ...packDetails,
      [name]: value,
    };

    setPackDetails((_state) => ({ ..._state, ...data }));
  };

  const onCurrencyChange = (e, sIndex) => {
    const { value } = e.target;
    const _value = Number(value ?? 0);

    if (_value < 1) {
      showSnackbar("Amount should be greater than 1");
      return;
    }
    let pack = { ...packDetails };
    pack.slots[sIndex].amount = _value;
    setPackDetails(pack);
  };

  const handleSlotType = (e, slotIndex) => {
    const { name, value } = e.target;

    let slotDetails = {
      ...packDetails.slots[slotIndex],
      [name]: value,
    };

    let updatedData = [];

    switch (value) {
      case "Player":
        updatedData = [dropProbability];
        if (packDetails.slots[slotIndex].dropProbabilities.length) {
          updatedData = packDetails.slots[slotIndex].dropProbabilities;
        }
        slotDetails = {
          ...slotDetails,
          dropProbabilities: updatedData,
        };
        break;
      case "Booster":
        updatedData = [boosterProbability];
        if (packDetails.slots[slotIndex].boosterProbabilities.length) {
          updatedData = packDetails.slots[slotIndex].boosterProbabilities;
        }
        slotDetails = {
          ...slotDetails,
          boosterProbabilities: updatedData,
        };
        break;
      default:
        // Handle unexpected cases or leave empty if nothing needs to be done
        console.warn(`Unexpected value: ${value}`);
    }

    setPackDetails((st) => ({
      ...st,
      slots: [
        ...packDetails.slots.slice(0, slotIndex),
        {
          ...slotDetails,
        },
        ...packDetails.slots.slice(slotIndex + 1),
      ],
    }));
  };

  const onDropAndBoosterChange = (e, slotIndex, nestedIndex) => {
    const { name, value } = e.target;
    const { name: _nameToChange, type } = getSlotTypeAndName(slotIndex);
    if (!PlayerAndBooster.includes(type)) return;

    setPackDetails((state) => ({
      ...state,
      slots: [
        ...packDetails.slots.slice(0, slotIndex),
        {
          ...packDetails.slots[slotIndex],
          [_nameToChange]: [
            ...packDetails.slots[slotIndex][_nameToChange]?.slice(
              0,
              nestedIndex
            ),
            {
              ...packDetails.slots[slotIndex][_nameToChange][nestedIndex],
              [name]: value,
            },
            ...packDetails.slots[slotIndex][_nameToChange]?.slice(
              nestedIndex + 1
            ),
          ],
        },
        ...packDetails.slots.slice(slotIndex + 1),
      ],
    }));
  };

  const onPlayerPoolAndNamedPlayerChange = (e, slotIndex) => {
    const { name, value } = e.target;
    let pack = {
      ...packDetails,
      slots: [
        ...packDetails.slots.slice(0, slotIndex),
        {
          ...packDetails.slots[slotIndex],
          [name]: value,
        },
        ...packDetails.slots.slice(slotIndex + 1),
      ],
    };

    setPackDetails(pack);
  };

  const add = (slotIndex) => {
    const { name, type } = getSlotTypeAndName(slotIndex);
    let _buildpack = (value) => ({
      ...packDetails,
      slots: [
        ...packDetails.slots.slice(0, slotIndex),
        {
          ...packDetails.slots[slotIndex],
          [name]: [...packDetails.slots[slotIndex][name], value],
        },
        ...packDetails.slots.slice(slotIndex + 1),
      ],
    });

    let pack;

    if (type === "Player") pack = _buildpack(dropProbability);

    if (type === "Booster") pack = _buildpack(boosterProbability);

    setPackDetails(pack);
  };

  const removeOne = (slotIndex, nestedIndex) => {
    const { name } = getSlotTypeAndName(slotIndex);
    let pack = { ...packDetails };
    pack.slots[slotIndex][name] = pack.slots[slotIndex][name]?.filter(
      (_, _nestIdx) => _nestIdx !== nestedIndex
    );
    setPackDetails(pack);
  };

  const removeAll = (slotIndex) => {
    const { name, type } = getSlotTypeAndName(slotIndex);
    let pack = { ...packDetails };
    pack.slots[slotIndex][name] =
      type === "Player" ? [dropProbability] : [boosterProbability];
    setPackDetails(pack);
  };

  const fetchPackInfo = async () => {
    setLoading(true);
    try {
      const resp = await packInfo(id);
      if (resp?.data) {
        const defaultPlayerOptions = Object.keys(resp.data.playerDetails).map(
          (pc) => {
            return {
              id: pc,
              label: buildLabel(resp?.data?.playerDetails[pc]),
            };
          }
        );

        setOptions((state) => ({
          ...state,
          defaultPlayerCards: resp.data.playerDetails,
          defaultPlayerCardOptions: defaultPlayerOptions,
        }));

        const slots = resp.data?.slots.map((slot) => {
          if (slot?.type === "NamedPlayer") {
            let player = resp?.data?.playerDetails[slot.playerCard];
            return {
              ...slot,
              playerCard: {
                id: slot.playerCard,
                label: buildLabel(player),
              },
            };
          } else if (slot?.type === "PlayerPool") {
            return {
              ...slot,
              playerPool: slot.playerPool?.map((pc) => {
                let player = resp?.data?.playerDetails[pc];
                return {
                  id: pc,
                  label: buildLabel(player),
                };
              }),
            };
          }
          return slot;
        });
        setOptions((state) => ({ ...state, slotCount: slots?.length || 1 }));
        setPackDetails({
          ...resp.data,
          slots: slots,
          type: [resp.data?.type],
        });
      }
      // showSnackbar(resp.message, "success");
      setLoading(false);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onSelectImage = (image) => {
    setOptions((st) => ({ ...st, selectedPackImage: image }));
    setPackDetails((state) => ({ ...state, packPredefinedImageType: "" }));
  };

  const upload = async (url) => {
    try {
      const resp = await uploadPackImage({
        data: options.selectedPackImage,
        url,
      });

      showSnackbar("Image Uploaded", "success");
      return resp;
    } catch (error) {
      showSnackbar("Error uploading image", "error");
    }
  };

  const fetchPackImages = async () => {
    const resp = await getPackImages();
    if (resp.data?.length) {
      setOptions((ops) => ({
        ...ops,
        images: resp.data?.map((obj) => ({
          ...obj,
          id: obj?.name,
          label: obj.name,
        })),
      }));
    }
  };

  useEffect(() => {
    fetchPackImages();
    if (id !== "create") {
      fetchPackInfo();
    }
  }, []);

  const renderView = (slot, index) => {
    switch (slot?.type) {
      case "Player":
        return (
          <PlayerSlot
            slotData={slot}
            slotIndex={index}
            handleChange={onDropAndBoosterChange}
            removeOne={removeOne}
          />
        );
      case "NamedPlayer":
        return (
          <NamedPlayerSlot
            slotData={slot}
            slotIndex={index}
            handleChange={onPlayerPoolAndNamedPlayerChange}
          />
        );
      case "Booster":
        return (
          <BoosterSlot
            slotData={slot}
            slotIndex={index}
            removeOne={removeOne}
            handleChange={onDropAndBoosterChange}
          />
        );
      case "Currency":
        return (
          <Box className={styles.field2}>
            <SimpleTextInput
              label="Amount"
              name="amount"
              type="number"
              value={packDetails.slots[index]?.amount}
              onChange={(e) => onCurrencyChange(e, index)}
            />
          </Box>
        );
      case "PlayerPool":
        return (
          <PlayerPoolSlot
            slotData={slot}
            slotIndex={index}
            handleChange={onPlayerPoolAndNamedPlayerChange}
            defaultPlayerCardOptions={options.defaultPlayerCardOptions}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <ContentShell
      showBackButton
      onBackButtonClick={() => navigate(-1)}
      title={`${id === "create" ? "Create" : "Edit"} Pack`}
      loading={loading}
      actions={
        <Box className={styles.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={submit}>
            Save
          </Button>
        </Box>
      }
    >
      <Box className={styles.container}>
        <Box className={styles.PackFormContainer}>
          <Box className={styles.formContainer}>
            <Box className={styles.field}>
              <SimpleTextInput
                name="name"
                label="Pack Name"
                value={packDetails.name}
                onChange={onChange}
              />
            </Box>
            <Box className={styles.field}>
              <SimpleMultiSelectInput
                label="Pack Type"
                name="type"
                value={packDetails.type}
                options={options.packTypeOptions}
                onChange={onChange}
              />
            </Box>
            {packDetails.type.includes("Store") && (
              <>
                {/* <Box className={styles.field}>
                  <SimpleMultiSelectInput
                    label="Operators"
                    name="oparators"
                    value={packDetails.operators}
                    options={options.operatorOptions}
                    onChange={onChange}
                  />
                </Box> */}
                <Box className={styles.field}>
                  <SimpleTextInput
                    name="price"
                    label="Pack Price"
                    // type="number"
                    value={packDetails.price}
                    onChange={onChange}
                  />
                </Box>
              </>
            )}
            {!options.selectedPackImage && (
              <Box className={styles.field}>
                <SimpleSelectInput
                  label="Pack Image"
                  name="packPredefinedImageType"
                  options={options.images}
                  value={packDetails?.packPredefinedImageType || ""}
                  onChange={onChange}
                />
              </Box>
            )}
            <Box className={styles.field}>
              <SimpleTextInput
                label="Pack Summary"
                name="summary"
                type="text"
                value={packDetails.summary}
                onChange={onChange}
              />
            </Box>
            <Box className={styles.field}>
              <SimpleSelectInput
                label="Territory"
                name="territory"
                value={packDetails?.territory || ""}
                options={sessionHelper.countryOptions}
                onChange={onChange}
              />
            </Box>
          </Box>
          <Box className={styles.imagePickerContainer}>
            <FilePicker
              value={options.images.find(
                (image) => image.id === packDetails.packPredefinedImageType
              )}
              name="packImage"
              onFileSelect={onSelectImage}
            />
            {packDetails?.imageUrl &&
              !options.selectedPackImage &&
              !packDetails.packPredefinedImageType && (
                <Box className={styles.prevContainer}>
                  <Box
                    component="img"
                    src={packDetails?.imageUrl}
                    alt="Preview"
                    className={styles.preview}
                  />
                </Box>
              )}
          </Box>
        </Box>
        <Box className={styles.slotRoot}>
          <Box className={styles.slotHeader}>
            <Typography className={styles.slotHeaderText}>
              Slot Configuration
            </Typography>
            <Box className={styles.smallField}>
              <SimpleSelectInput
                label="Number of Slots"
                name="noOfSlots"
                options={options.noOfSlotOptions}
                value={options.slotCount}
                onChange={updateSlotCount}
              />
            </Box>
          </Box>

          <Divider />
          <Box className={styles.slotEditor}>
            {Array.from({ length: options.slotCount }, (slot, k) => {
              return (
                <Box key={k} className={styles.slotContainer}>
                  <Box className={styles.slotSubHeaderContainer}>
                    <Box className={styles.slotSubHeader}>
                      <Typography className={styles.slotNumberText}>
                        Slot No - {k + 1}
                      </Typography>
                      <Box className={styles.field2}>
                        <SimpleSelectInput
                          label="Slot Type"
                          name="type"
                          options={options.slotTypeOptions}
                          value={packDetails.slots[k]?.type}
                          onChange={(e) => handleSlotType(e, k)}
                        />
                      </Box>
                    </Box>
                    <AddAndDelete
                      isHidden={
                        !PlayerAndBooster.includes(packDetails.slots[k]?.type)
                      }
                      add={() => add(k)}
                      remove={() => removeAll(k)}
                    />
                  </Box>
                  {renderView(packDetails.slots[k], k)}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default PackEditor;

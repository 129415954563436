import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { FaUsers, FaGamepad, FaMoneyBillWave, FaTrophy } from "react-icons/fa";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  transition: "transform 0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const StatsIcon = styled(Box)({
  fontSize: "2.5rem",
  marginBottom: "1rem",
  color: "#1976d2",
});

const Dashboard = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  const statsData = [
    { title: "Total Players", value: "150,234", icon: <FaUsers /> },
    { title: "Active Games", value: "1,432", icon: <FaGamepad /> },
    { title: "Revenue", value: "$534,213", icon: <FaMoneyBillWave /> },
    { title: "Achievements", value: "45,676", icon: <FaTrophy /> },
  ];

  const playerData = [
    {
      id: 1,
      username: "GamerPro",
      level: 45,
      score: 98500,
      achievements: 23,
      lastActive: "2024-01-15",
    },
    {
      id: 2,
      username: "PixelHunter",
      level: 38,
      score: 75300,
      achievements: 18,
      lastActive: "2024-01-14",
    },
    {
      id: 3,
      username: "StarQuester",
      level: 52,
      score: 125000,
      achievements: 31,
      lastActive: "2024-01-15",
    },
    {
      id: 4,
      username: "LevelMaster",
      level: 60,
      score: 150000,
      achievements: 42,
      lastActive: "2024-01-13",
    },
    {
      id: 5,
      username: "GameWizard",
      level: 41,
      score: 89000,
      achievements: 20,
      lastActive: "2024-01-15",
    },
    {
      id: 6,
      username: "QuestSeeker",
      level: 35,
      score: 65000,
      achievements: 15,
      lastActive: "2024-01-12",
    },
  ];

  const barChartData = [
    { name: "Jan", players: 4000, revenue: 2400 },
    { name: "Feb", players: 3000, revenue: 1398 },
    { name: "Mar", players: 2000, revenue: 9800 },
    { name: "Apr", players: 2780, revenue: 3908 },
    { name: "May", players: 1890, revenue: 4800 },
    { name: "Jun", players: 2390, revenue: 3800 },
  ];

  const pieChartData = [
    { name: "Mobile", value: 400 },
    { name: "Desktop", value: 300 },
    { name: "Console", value: 300 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  const filteredData = playerData.filter((player) =>
    player.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 3, bgcolor: "#f5f5f5", minHeight: "100vh" }}>
      <Typography
        variant="h4"
        sx={{ mb: 4, fontWeight: "bold", color: "#1976d2" }}
      >
        Dummy Admin Dashboard
      </Typography>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        {statsData.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StyledCard>
              <CardContent sx={{ textAlign: "center" }}>
                <StatsIcon>{stat.icon}</StatsIcon>
                <Typography variant="h6" component="div">
                  {stat.title}
                </Typography>
                <Typography variant="h4" sx={{ mt: 1, fontWeight: "bold" }}>
                  {stat.value}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Player Activity
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={barChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <ChartTooltip />
                <Legend />
                <Bar dataKey="players" fill="#8884d8" />
                <Bar dataKey="revenue" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Platform Distribution
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label
                >
                  {pieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <ChartTooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ width: "100%" }}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Player Statistics
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search players..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TableContainer>
                <Table aria-label="player statistics table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Username</TableCell>
                      <TableCell align="right">Level</TableCell>
                      <TableCell align="right">Score</TableCell>
                      <TableCell align="right">Achievements</TableCell>
                      <TableCell>Last Active</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id} hover>
                          <TableCell component="th" scope="row">
                            {row.username}
                          </TableCell>
                          <TableCell align="right">{row.level}</TableCell>
                          <TableCell align="right">
                            {row.score.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">
                            {row.achievements}
                          </TableCell>
                          <TableCell>{row.lastActive}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;

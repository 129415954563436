/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "styles/pack/slots";
import DeleteIcon from "@mui/icons-material/Delete";
import { SimpleTextInput } from "components/FormTextInput";
import classNames from "classnames";
import { useSnackbar } from "hooks/useSnackbar";
import SearchableInput from "components/SearchableInput";
import { getPlayerCards } from "services/playerCardService";
import debounce from "lodash.debounce";
import { getBoosters } from "services/packServices";
import { useLocation } from "react-router-dom";

const defaultPageSize = 10,
  defaultPageNo = 1;

function validateNumericFields(data) {
  const errors = [];

  data.forEach((slot, index) => {
    if (slot?.type === "Currency" && slot.amount !== undefined) {
      slot.amount = Number(slot.amount);
      if (isNaN(slot.amount) || slot.amount < 1) {
        errors.push({
          index,
          field: "amount",
          error: `Invalid 'amount' field: ${slot.amount} (must be a number between 1 and 100)`,
        });
      }
    }

    if (slot?.type === "Player" && slot.dropProbabilities) {
      let sum = 0;
      slot.dropProbabilities.forEach((prob, probIndex) => {
        prob.probability = Number(prob.probability);
        prob.ovrMin = Number(prob.ovrMin);
        prob.ovrMax = Number(prob.ovrMax);

        sum += prob.probability;

        if (
          isNaN(prob.probability) ||
          prob.probability < 1 ||
          prob.probability > 100 ||
          isNaN(prob.ovrMin) ||
          prob.ovrMin < 1 ||
          prob.ovrMin > 100 ||
          isNaN(prob.ovrMax) ||
          prob.ovrMax < 1 ||
          prob.ovrMax > 100
        ) {
          errors.push({
            index,
            field: "dropProbabilities",
            error: `Invalid 'dropProbabilities' at PlayerSlot (index- ${index}): range should be 1-100`,
          });
        }
      });

      if (sum !== 100) {
        errors.push({
          index,
          field: "boosterProbabilities",
          error: `Total sum of all the Drop Probability should be equal to 100`,
        });
      }
    }

    if (slot?.type === "Booster" && slot.boosterProbabilities) {
      let sum = 0;
      slot.boosterProbabilities.forEach((prob, probIndex) => {
        prob.probability = Number(prob.probability);
        sum += prob.probability;
        if (
          isNaN(prob.probability) ||
          prob.probability < 1 ||
          prob.probability > 100
        ) {
          errors.push({
            index,
            field: "boosterProbabilities",
            error: `Invalid 'boosterProbabilities' at BoosterSlot (index- ${probIndex}): range should be 1-100`,
          });
        }
      });

      if (sum !== 100) {
        errors.push({
          index,
          field: "boosterProbabilities",
          error: `Total sum of all the Booster Probability should be equal to 100`,
        });
      }
    }
  });

  return {
    valid: errors.length === 0,
    errors,
  };
}

const buildLabel = (player) =>
  `${player.fullName} - ${player.nationality} - ${player.club}`;

const AdditionalDataViewer = ({ data, label, id }) => {
  const styles = useStyles();
  return (
    <Box style={{ display: "column" }}>
      <Typography className={styles.additionDetailsLabel}>{label}</Typography>
      <Box className={styles.additionalDataContainer}>
        <Typography className={styles.additionDetailsText}>
          {data?.[id]}
        </Typography>
      </Box>
    </Box>
  );
};

const AddAndDelete = ({ add, remove, isHidden }) => {
  const styles = useStyles();

  return (
    <Box
      className={classNames(styles.addRemoveContainer, {
        [styles.hide]: isHidden,
      })}
    >
      <Button variant="contained" onClick={add}>
        Add Row
      </Button>
      <Button variant="contained" onClick={remove}>
        Remove All
      </Button>
    </Box>
  );
};

const PlayerSlot = ({ slotData, slotIndex, handleChange, removeOne }) => {
  const styles = useStyles();
  // const { showSnackbar } = useSnackbar();

  const onChange = (e, dIndex) => {
    const { name, value } = e.target;

    handleChange(
      { target: { name: name, value: Number(value) } },
      slotIndex,
      dIndex
    );
  };

  return (
    <>
      {slotData?.dropProbabilities.map((dropProb, dropIndex) => {
        return (
          <Box key={dropIndex} className={styles.dropProbabilities}>
            <Box className={styles.smallField}>
              <SimpleTextInput
                name="ovrMin"
                label="OVR Min"
                value={dropProb?.ovrMin}
                onChange={(e) => onChange(e, dropIndex)}
              />
            </Box>
            <Box className={styles.smallField}>
              <SimpleTextInput
                name="ovrMax"
                label="OVR Max"
                value={dropProb?.ovrMax}
                onChange={(e) => onChange(e, dropIndex)}
              />
            </Box>
            <Box className={styles.smallField}>
              <SimpleTextInput
                name="probability"
                label="Probability (%)"
                value={dropProb?.probability}
                onChange={(e) => onChange(e, dropIndex)}
              />
            </Box>
            {dropIndex !== 0 && (
              <DeleteIcon
                style={{
                  marginTop: "30px",
                  color: "red",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() => removeOne(slotIndex, dropIndex)}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};

const NamedPlayerSlot = ({ slotData, slotIndex, handleChange }) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [cardDetails, setCardDetails] = useState(null);
  const [pagination] = useState({
    pageSize: defaultPageSize,
    pageNo: defaultPageNo,
  });
  const [options, setOptions] = useState({
    hasMore: false,
    playerCards: [],
    playerCardOptions: [],
    addiotionDataToShow: [
      {
        id: "fullName",
        label: "Full Name",
      },
      {
        id: "rarity",
        label: "Rarity",
      },
      {
        id: "overallRating",
        label: "OVR",
      },
      {
        id: "club",
        label: "Club",
      },
      {
        id: "preferredPosition",
        label: "Position",
      },
    ],
  });

  const _fetchPlayerCards = async (filters) => {
    setLoading(true);
    try {
      const resp = await getPlayerCards(filters);
      setOptions((state) => ({
        ...state,
        hasMore: resp?.morePages,
        playerCards: resp.items,
        playerCardOptions: resp?.items?.map((player) => ({
          id: player?._id,
          label: buildLabel(player),
        })),
      }));
      setLoading(false);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onSearchChange = async (searchValue) => {
    if (searchValue !== "") {
      _fetchPlayerCards({
        ...pagination,
        search: searchValue,
        skipPagination: true,
      });
    } else {
      _fetchPlayerCards(pagination);
    }
  };

  const optimizeSearch = debounce(onSearchChange, 300);

  const onChange = (e) => {
    setCardDetails(
      options.playerCards.find((item) => item?._id === e?.target?.value?.id)
    );
    handleChange(e, slotIndex);
  };

  useEffect(() => {
    _fetchPlayerCards(pagination);
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexFlow: "row wrap",
        marginLeft: 8,
        gap: 5,
        alignItems: "flex-end",
      }}
    >
      <SearchableInput
        label={"Player Card"}
        name={"playerCard"}
        selected={slotData?.playerCard?.id ? slotData.playerCard : null}
        options={options.playerCardOptions}
        onInputChange={optimizeSearch}
        onOptionSelect={onChange}
        placeholder="Select a player"
        loading={loading}
        customStyle={{ width: "220px" }}
      />
      <Box
        className={classNames(styles.namedPlayerDetailsContainer, {
          [styles.hide]: cardDetails == null,
        })}
      >
        {cardDetails &&
          options.addiotionDataToShow.map((_inputs, index) => {
            return (
              <AdditionalDataViewer
                key={index}
                data={cardDetails}
                {..._inputs}
              />
            );
          })}
      </Box>
    </Box>
  );
};

const BoosterSlot = ({ slotData, slotIndex, handleChange, removeOne }) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const [pagination] = useState({
    pageSize: defaultPageSize,
    pageNo: defaultPageNo,
  });
  const [options, setOptions] = useState({
    loaded: false,
    hasMore: false,
    boosters: [],
    boosterOptions: [],
  });

  const onChange = (e, boosterIndex) => {
    const { name, value } = e.target;
    if (name === "probability") {
      handleChange(
        { target: { name: name, value: Number(value) } },
        slotIndex,
        boosterIndex
      );
    } else handleChange(e, slotIndex, boosterIndex);
  };

  const fetchBoosters = async () => {
    setLoading(true);
    try {
      const resp = await getBoosters();
      const boosterOptions = resp.data?.map((boost) => ({
        id: boost?._id,
        label: boost?.displayName,
      }));

      setOptions((op) => ({
        ...op,
        hasMore: resp.morePages,
        boosters: resp.data,
        boosterOptions: boosterOptions,
      }));

      setOptions((state) => ({ ...state, loaded: true }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  const onSearchChange = async (searchValue) => {
    if (searchValue !== "") {
      fetchBoosters({
        ...pagination,
        search: searchValue,
        skipPagination: true,
      });
    } else {
      fetchBoosters(pagination);
    }
  };

  const optimizeSearch = debounce(onSearchChange, 300);

  useEffect(() => {
    fetchBoosters(pagination);
  }, []);

  return (
    <Box className={styles.rowProbabilities}>
      {options.loaded &&
        slotData?.boosterProbabilities?.map((booster, boosterIndex) => {
          let selected;

          if (id !== "create")
            selected = options?.boosterOptions.find(
              (bp) => bp.id === booster?.boosterId
            );
          else selected = booster?.boosterId;
          return (
            <Box key={boosterIndex} className={styles.dropProbabilities}>
              <Box style={{ display: "flex", gap: 5 }}>
                <SearchableInput
                  label={"Boosters"}
                  name={"boosterId"}
                  options={options.boosterOptions}
                  selected={selected}
                  onInputChange={optimizeSearch}
                  onOptionSelect={(e) => onChange(e, boosterIndex)}
                  placeholder="Select a boost"
                  loading={loading}
                  customStyle={{
                    width: "220px",
                  }}
                />

                <SimpleTextInput
                  label={"Boost Probability (%)"}
                  name={"probability"}
                  value={booster?.probability}
                  onChange={(e) => onChange(e, boosterIndex)}
                />
                {boosterIndex !== 0 && (
                  <DeleteIcon
                    style={{
                      marginTop: "30px",
                      color: "red",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => removeOne(slotIndex, boosterIndex)}
                  />
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

const PlayerPoolSlot = ({
  slotData,
  slotIndex,
  handleChange,
  defaultPlayerCardOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [pagination] = useState({
    pageSize: defaultPageSize,
    pageNo: defaultPageNo,
  });
  const [options, setOptions] = useState({
    hasMore: false,
    playerCards: [],
    playerCardOptions: [],
    addiotionDataToShow: [
      {
        id: "fullName",
        label: "Full Name",
      },
      {
        id: "rarity",
        label: "Rarity",
      },
      {
        id: "club",
        label: "Club",
      },
      {
        id: "preferredPosition",
        label: "Position",
      },
    ],
  });

  const _fetchPlayerCards = async (filters) => {
    setLoading(true);
    try {
      const resp = await getPlayerCards(filters);
      let _pcOptions = resp?.items?.map((player) => {
        return {
          id: player?._id,
          label: buildLabel(player),
        };
      });
      let pcOptions = [];
      defaultPlayerCardOptions.forEach((pcOption) => {
        let isExist = _pcOptions.find((item) => item?.id === pcOption.id);

        if (!isExist) pcOptions.push(pcOption);
      });
      setOptions((state) => ({
        ...state,
        hasMore: resp?.morePages,
        playerCards: resp.items,
        playerCardOptions: [..._pcOptions, ...pcOptions],
      }));

      setLoading(false);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onSearchChange = async (searchValue) => {
    if (searchValue !== "") {
      _fetchPlayerCards({
        ...pagination,
        search: searchValue,
        skipPagination: true,
      });
    } else {
      _fetchPlayerCards(pagination);
    }
  };

  const optimizeSearch = debounce(onSearchChange, 300);

  const onChange = (e) => handleChange(e, slotIndex);

  useEffect(() => {
    _fetchPlayerCards(pagination);
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexFlow: "row wrap",
        marginLeft: 8,
        gap: 5,
        alignItems: "flex-end",
      }}
    >
      <SearchableInput
        selected={slotData?.playerPool || []}
        isMultiSelect={true}
        label={"Player Pool"}
        name={"playerPool"}
        options={options.playerCardOptions}
        onInputChange={optimizeSearch}
        onOptionSelect={onChange}
        placeholder="Select player cards"
        loading={loading}
        customStyle={{
          width: "500px",
        }}
      />
    </Box>
  );
};

export {
  buildLabel,
  AddAndDelete,
  PlayerSlot,
  NamedPlayerSlot,
  BoosterSlot,
  PlayerPoolSlot,
  validateNumericFields,
};

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Container,
  styled,
  keyframes,
} from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import configData from "config/config.json";

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(3),
  background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
}));

const ErrorNumber = styled(Typography)({
  fontSize: "12rem",
  fontWeight: 700,
  animation: `${bounce} 2s infinite`,
  marginBottom: "1rem",
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(1.5, 4),
  borderRadius: "30px",
  fontSize: "1.1rem",
  transition: "all 0.3s ease-in-out",
  animation: `${fadeIn} 1s ease-in`,
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  color: "white",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
  },
}));

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate(configData.routes.dashboard);
  };

  return (
    <StyledContainer maxWidth="md" role="main" aria-label="404 error page">
      <Box
        sx={{
          animation: `${fadeIn} 1s ease-in`,
          position: "relative",
        }}
      >
        <ErrorNumber variant="h1" aria-label="404">
          404
        </ErrorNumber>
        <Typography
          variant="h3"
          component="h2"
          sx={{ mb: 3, fontWeight: 600 }}
          aria-label="Page not found"
        >
          Oops! Page Not Found
        </Typography>
        <Typography
          variant="h6"
          sx={{ mb: 4, color: "text.secondary" }}
          aria-label="Error description"
        >
          We apologize, but the page you're looking for seems to have vanished
          into the digital void. Don't worry, you can easily return to our
          homepage.
        </Typography>
        <StyledButton
          onClick={handleHomeClick}
          startIcon={<IoMdArrowRoundBack />}
          aria-label="Return to homepage"
          focusVisible={{
            outline: "2px solid #FE6B8B",
            outlineOffset: "2px",
          }}
        >
          Back to Home
        </StyledButton>
      </Box>
    </StyledContainer>
  );
};

export default NotFoundPage;

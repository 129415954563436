import { Client } from "./_clientService";

const executeRequest = async (options) => {
  try {
    const resp = await new Client(options)[options.method]();
    return resp;
  } catch (error) {
    throw error;
  }
};

async function create(params) {
  return executeRequest({
    path: "/operator/create",
    payload: params,
    method: "post",
  });
}

async function update(params, operatorId) {
  return executeRequest({
    path: `/operator/update/${operatorId}`,
    payload: params,
    method: "patch",
  });
}

async function getOperators(params) {
  let payload = {
    pageNo: params?.pageNo || 1,
    pageSize: params?.pageSize || 10,
    sortDirection: params?.sortDirection || -1,
    sortBy: params?.sortBy || "createdAt",
    search: params?.search ?? "",
  };

  if (params?.skipPagination) payload["skipPagination"] = true

  const urlParams = new URLSearchParams(payload).toString();

  return executeRequest({
    path: `/operator/list?${urlParams}`,
    method: "get",
  });
}

async function getOperatorInfo(operatorId) {
  if (!operatorId) throw new Error("No operator id provided");

  return executeRequest({
    path: `/operator/info/${operatorId}`,
    method: "get",
  });
}

export { create, update, getOperators, getOperatorInfo };

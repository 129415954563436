import React from "react";

const usePaginationHook = ({ totalItems, defaultPageSize = 50, defaultPageNo = 1 }) => {
  const [pageNo, setPageNo] = React.useState(defaultPageNo ?? 1);
  const [pageSize, setPageSize] = React.useState(defaultPageSize ?? 50);

  const onChangePage = (_pageNo) => setPageNo(_pageNo);

  const onPageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPageNo(1)
  }

  const onPrev = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNext = () => {
    setPageNo((prev) => prev + 1);
  };

  // const hasNext = () => 

  return {
    onChangePage,
    onPageSizeChange,
    onPrev,
    onNext,
    pageNo,
    pageSize,
  };
};

export default usePaginationHook;

function clubTablePosition() {
  return [
    {
      id: "name",
      label: `Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "abbreviationName",
      label: `Abbreviation Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Operator",
    },
    {
      id: "textColor",
      label: `Text Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "start time of the transaction",
    },
    {
      id: "backgroundColor",
      label: `Background Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "start time of the transaction",
    },
    { id: "actions", label: "Actions" }
  ];
}

export default clubTablePosition;

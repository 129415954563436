/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  canvasContainer: {},
  playerCardCanvas: {
    alignSelf: "center",
    height: 400,
    width: 370,
    // padding: "6px 10px 10px 6px",
    // borderRadius: "0.35rem",
    backgroundColor: "#dfdfdf",
  },
  downloadContainer: {
    marginTop: 20,
    display: "flex",
    flexFlow: "row wrap",
    gap: 10,
  },
  downloadButton: {
    fontSize: 12,
    width: "100%",
    maxWidth: 130,
  },
}));

const PlayerCardCanvas = ({ playerDetails, sendToCanvas }) => {
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  // const download = (name) => sendToCanvas(`download_image`, { name });

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        sendToCanvas("INIT", playerDetails);
      }, 0);
    }
  }, [isLoading]);

  return (
    <Box className={styles.canvasContainer}>
      <Box className={styles.playerCardCanvas}>
        <iframe
          id="playerCard"
          src="canvas/index.html"
          width="100%"
          height="100%"
          onLoad={handleLoad}
          title="Player Card"
          frameBorder={0}
        />
      </Box>
      <Box className={styles.downloadContainer}>
        {/* <Button
          startIcon={<Download />}
          type="submit"
          onClick={() => download("card")}
          variant="contained"
          className={styles.downloadButton}
        >
          Card
        </Button>
        <Button
          startIcon={<Download />}
          type="submit"
          onClick={() => download("avatar")}
          variant="contained"
          className={styles.downloadButton}
        >
          Avatar
        </Button> */}
      </Box>
    </Box>
  );
};

export default PlayerCardCanvas;

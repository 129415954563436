import { makeStyles } from "@mui/styles";
import { opacity2hex } from "utils/miscUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "row wrap",
    gap: "10px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      gap: 25,
    },
    [theme.breakpoints.up("sm")]: {
      gap: 30,
    },
  },
  editorContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  field: {
    width: 180,
  },
  smallField: {
    width: 120,
  },
  submitButton: {
    width: "100%",
    backgroundColor: "#1D1030",
    color: "white",
    fontWeight: "bold",
  },
  formContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: "10px",
    width: "100%",
  },
  milestoneContainer: {
    marginTop: 10,
    padding: 14,
    height: "100%",
    width: "100%",
    backgroundColor: "#f9f9f9" + opacity2hex("0.80"),
    borderRadius: 12,
    boxShadow: `box-shadow: 1px 0px 37px -4px rgba(0,0,0,0.15);
  -webkit-box-shadow: 1px 0px 37px -4px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 0px 37px -4px rgba(0,0,0,0.15);`,
  },
  milestoneText: {
    fontWeight: "bold",
    fontSize: 18,
  },
  milestoneFormContainer: {
    display: "flex",
    flexFlow: "column wrap",
    margin: 10,
    height: "100%",
    gap: "10px",
  },

  milestoneInputContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 10,
  },

  milestoneHeaderText: {
    fontSize: 14,
    fontWeight: "bold",
  },

  milestoneRoot: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: 10,
  },

  buttonContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 10,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      gap: 5,
    },
  },
  delete: {
    marginTop: "30px",
    marginLeft: "10px",
    color: "red",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginLeft: 0,
    },
  },
  milestoneButtonConatiner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0",
  },
}));

export default useStyles;

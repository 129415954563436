/* eslint-disable react-hooks/exhaustive-deps */
import ContentShell from "components/ContentShell";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import configData from "config/config.json";
import { useSnackbar } from "hooks/useSnackbar";
import useStyles from "styles/brand/brandEditor";
import { SimpleTextInput } from "components/FormTextInput";
import { SimpleSelectInput } from "components/FormSelectInput";
import { create, getBrandInfo, update } from "services/brandServices";
import sessionHelperInstance from "services/sessionHelper";

const _brandDetails = {
  platform: "",
  operator: "",
  brandName: "",
  secretKey: "",
  winTax: 0,
  betTax: 0,
  domain: "",
  homeUrl: "",
};

const BrandEditor = () => {
  const styles = useStyles();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [brandDetails, setBrandDetails] = useState(_brandDetails);

  const cancel = () => navigate(configData.routes.brands.brands);

  const submit = async () => {
    try {
      setLoading(true);
      let response;
      if (id === "create") {
        response = await create({
          ...brandDetails,
          winTax: Number(brandDetails.winTax),
          betTax: Number(brandDetails.betTax),
        });

        // append new data to session helper
        sessionHelperInstance.updatePBO(response?.data, "brand");
      } else {
        response = await update(
          {
            brandName: brandDetails.brandName,
            winTax: Number(brandDetails.winTax),
            betTax: Number(brandDetails.betTax),
            domain: brandDetails.domain,
            homeUrl: brandDetails.homeUrl,
          },
          id
        );
      }

      showSnackbar(response.message, "success");
      setLoading(false);
      navigate(configData.routes.brands.brands);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onChange = async (e) => {
    const { name, value } = e?.target;

    setBrandDetails((state) => ({ ...state, [name]: value }));
  };

  const fetchBrandInfo = async () => {
    try {
      setLoading(true);
      const resp = await getBrandInfo(id);
      setBrandDetails(resp.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  useEffect(() => {
    if (id !== "create") fetchBrandInfo();
  }, []);

  return (
    <ContentShell
      showBackButton
      onBackButtonClick={cancel}
      title={`${id === "create" ? "Create" : "Edit"} Brand`}
      loading={loading}
      actions={
        <Box className={styles.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={submit}>
            Save
          </Button>
        </Box>
      }
    >
      <Box className={styles.container}>
        <Box className={styles.firstFormContainer}>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Brand Name"
              name="brandName"
              value={brandDetails.brandName}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleSelectInput
              readOnly={id !== "create"}
              readonlyvalue={
                sessionHelperInstance.platformOptions.find(
                  (el) => el.id === brandDetails.platform
                )?.label
              }
              label="Platform"
              name="platform"
              value={brandDetails.platform}
              options={sessionHelperInstance.platformOptions}
              onChange={onChange}
            />
          </Box>
          {brandDetails.platform && (
            <Box className={styles.field}>
              <SimpleSelectInput
                readOnly={id !== "create"}
                readonlyvalue={
                  sessionHelperInstance.operatorOptions.find(
                    (el) => el.id === brandDetails.operator
                  )?.label
                }
                label="Operator"
                name="operator"
                value={brandDetails.operator}
                options={sessionHelperInstance.operatorOptions.filter(
                  (item) => item.platform === brandDetails?.platform
                )}
                onChange={onChange}
              />
            </Box>
          )}
          {id === "create" && (
            <Box className={styles.field}>
              <SimpleTextInput
                label="Secret Key"
                name="secretKey"
                value={brandDetails.secretKey}
                onChange={onChange}
              />
            </Box>
          )}
          <Box className={styles.field}>
            <SimpleTextInput
              label="Win Tax"
              name="winTax"
              value={brandDetails.winTax}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Bet Tax"
              name="betTax"
              value={brandDetails.betTax}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Domain Url"
              name="domain"
              value={brandDetails.domain}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Home Url"
              name="homeUrl"
              value={brandDetails.homeUrl}
              onChange={onChange}
            />
          </Box>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default BrandEditor;

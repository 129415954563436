import { userRoles } from "./constants";

export const superAdmin = userRoles.SUPER_ADMIN;
export const support = userRoles.SUPPORT;
export const admin = userRoles.ADMIN;

export const PermittedRoles = {
    BOOSTERS: {
        LIST_ROLES: [superAdmin],
    },
    BRAND: {
        INFO_ROLES: [superAdmin],
        LIST_ROLES: [superAdmin, admin],
        CREATE_ROLES: [superAdmin],
        UPDATE_ROLES: [superAdmin],
    },
    CLUB: {
        INFO_ROLES: [superAdmin],
        LIST_ROLES: [superAdmin, admin],
        CREATE_ROLES: [superAdmin],
        UPDATE_ROLES: [superAdmin],
        DELETE_ROLES: [superAdmin],
    },
    COUNTRIES: {
        LIST_ROLES: [superAdmin, admin, support],
    },
    GAME: {
        INFO_ROLES: [],
        LIST_ROLES: [],
        CREATE_ROLES: [superAdmin, admin],
        UPDATE_ROLES: [superAdmin, admin],
    },
    LEAGUE: {
        INFO_ROLES: [superAdmin],
        LIST_ROLES: [superAdmin, admin],
        CREATE_ROLES: [superAdmin],
        UPDATE_ROLES: [superAdmin],
    },
    MISC: {
        OVR_FORMULA_ROLES: [superAdmin, admin, support],
    },
    OPERATOR: {
        INFO_ROLES: [superAdmin],
        LIST_ROLES: [superAdmin, admin],
        CREATE_ROLES: [superAdmin],
        UPDATE_ROLES: [superAdmin],
    },
    OPERATOR_GAME: {
        INFO_ROLES: [],
        LIST_ROLES: [],
        CREATE_ROLES: [superAdmin, admin],
        UPDATE_ROLES: [superAdmin, admin],
        SYNC_GAMES_LIST_ROLES: [superAdmin],
        UPDATE_ALL_OPERATOR_GAMES_ROLES: [superAdmin, admin],
    },
    PLATFORM: {
        INFO_ROLES: [superAdmin],
        LIST_ROLES: [superAdmin, admin],
        CREATE_ROLES: [superAdmin],
        UPDATE_ROLES: [superAdmin],
    },
    PLAYER: {
        TEMPLATE: {
            LIST_ROLES: [superAdmin],
            CREATE_ROLES: [superAdmin],
        },
        CARD: {
            INFO_ROLES: [superAdmin],
            LIST_ROLES: [superAdmin, admin],
            CREATE_ROLES: [superAdmin],
            UPDATE_ROLES: [superAdmin],
        },
        PACK: {
            INFO_ROLES: [superAdmin],
            LIST_ROLES: [superAdmin, admin],
            CREATE_ROLES: [superAdmin],
            UPDATE_ROLES: [superAdmin],
            PREDEFINED_IMAGES_ROLES: [superAdmin],
        },
    },
    POSITION: {
        INFO_ROLES: [superAdmin],
        LIST_ROLES: [superAdmin, admin],
        CREATE_ROLES: [superAdmin],
        UPDATE_ROLES: [superAdmin],
        DELETE_ROLES: [superAdmin],
    },
    USER: {
        LIST_ROLES: [],
        CREATE_ROLES: [superAdmin],
        UPDATE_ROLES: [superAdmin],
    },
    REPORT: {
        INFO_ROLES: [superAdmin, support],
    },
    DASHBOARD: {
        INFO_ROLES: [superAdmin, admin, support],
    },
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "components/Table";
import ContentShell from "components/ContentShell";
import { Button, Box } from "@mui/material";
import SearchInput from "components/SearchInput";
import usePaginationHook from "hooks/usePagination";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "hooks/useSnackbar";
import configData from "config/config.json";
import { operatorTableColumns } from "utils/tableHelper/pobTableHelper";
import { getOperators } from "services/operatorService";
import sessionHelper from "services/sessionHelper";
import { PermittedRoles } from "utils/permissions";

const useStyles = makeStyles((theme) => ({}));

const defaultPageSize = 10,
  defaultPageNo = 1;

const RowActions = ({ rowId, data }) => {
  const navigate = useNavigate();
  const onEdit = (data) =>
    navigate(configData.routes.operators.edit?.replace(":id", data?._id));
  return <Button onClick={() => onEdit(data)}>Edit</Button>;
};

const Operators = () => {
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.PLATFORM.UPDATE_ROLES
  );

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({ defaultPageSize, defaultPageNo });

  const fetchData = async (v) => {
    setLoading(true);
    try {
      const resp = await getOperators({
        pageNo,
        pageSize: pageSize,
        search: v,
      });
      // showSnackbar(resp.message, "success");
      setFilteredList(resp?.data?.operators);
      setLoading(false);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setSearchValue(value);
    onPageSizeChange({ target: { value: defaultPageSize } });
    fetchData(value);
  };

  const createOperator = () => navigate(configData.routes.operators.create);

  useEffect(() => {
    const initialColumns = operatorTableColumns().filter(
      (col) => col.id !== "actions" || hasEditPermission
    );
    setColumns(initialColumns);

    fetchData(searchValue);
  }, [pageNo, pageSize, hasEditPermission]);

  return (
    <ContentShell
      title="Operators"
      loading={loading}
      actions={
        <>
          <SearchInput
            value={searchValue}
            placeholder="Search a operator"
            onSearch={onSearch}
          />
          {sessionHelper.hasRole(PermittedRoles.LEAGUE.CREATE_ROLES) && (
            <Button
              type="submit"
              variant="contained"
              className={styles.editButton}
              onClick={createOperator}
            >
              Create Operator
            </Button>
          )}
        </>
      }
    >
      <Box className={styles.playerCardParent}>
        <Table
          columns={columns}
          data={filteredList}
          currentPage={pageNo}
          actions={RowActions}
          pageSize={pageSize}
          totalEntries={filteredList.length}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
          highlightAltRow={true}
        />
      </Box>
    </ContentShell>
  );
};

export default Operators;

function platformTableColumns() {
  return [
    {
      id: "platformName",
      label: `Platform`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "createdAt",
      label: `Created At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "updatedAt",
      label: `Updated At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    { id: "actions", label: "Actions" },
  ];
}

function operatorTableColumns() {
  return [
    {
      id: "operatorName",
      label: `Operator`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "platformName",
      label: `Platform`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "createdAt",
      label: `Created At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "updatedAt",
      label: `Updated At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    { id: "actions", label: "Actions" },
  ];
}

function brandTableColumns() {
  return [
    {
      id: "brandName",
      label: `Brand Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "platformName",
      label: `Platform`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "operatorName",
      label: `Operator Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "winTax",
      label: `Win Tax`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "betTax",
      label: `Bet Tax`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "isActive",
      label: `Is Active`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "createdAt",
      label: `Created At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "updatedAt",
      label: `Updated At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    { id: "actions", label: "Actions" },
  ];
}

export { platformTableColumns, brandTableColumns, operatorTableColumns };

import { makeStyles } from "@mui/styles";
import { opacity2hex } from "utils/miscUtils";

const useStyles = makeStyles((theme) => ({
  slotRoot: {},
  addRemoveContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 2,
    padding: 6,
  },
  dropProbabilities: {
    marginLeft: 8,
    display: "flex",
    flexFlow: "row wrap",
    gap: 2,
  },
  slotSubHeaderContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  slotSubHeader: {},
  container: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
    },
  },

  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "flex-start",
    marginTop: "33px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
      justifyContent: "center",
    },
  },
  formContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: "10px",
  },
  PackFormContainer: {
    display: "flex",
    flexFlow: "column wrap",
  },

  imagePickerContainer: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexFlow: "row wrap"
  },

  noPlayersContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 150,
    backgroundColor: "#D3D3D3",
    borderRadius: 12,
    width: "100%",
    marginTop: "33px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  smallField: {
    width: 120,
  },
  field: {
    width: 220,
    // margin: "0 8px 5px 8px",
  },
  field2: {
    width: 180,
    margin: "0 8px 5px 8px",
  },
  field3: {
    width: 250,
    margin: "0 8px 5px 8px",
    marginTop: 15,
    marginBottom: 10,
  },
  field5: {
    marginTop: 15,
    width: 400,
    margin: "0 8px 5px 8px",
  },
  field4: {
    width: 180,
    margin: "0 8px 5px 8px",
    marginTop: 15,
  },
  button: {
    width: "150px",
    height: 45,
  },
  submitButton: {
    marginTop: 20,
    width: "150px",
    height: 45,
  },
  savecancel: {
    marginTop: 20,
    width: "100px",
    height: 40,
  },
  cancelb: {
    marginTop: 20,
    width: "100px",
    height: 40,
    backgroundcolor: "red",
  },

  removeButton: {
    position: "absolute",
    top: "-14px",
    right: "-7px",
    padding: 0,
    background: theme.palette.secondary.main,
    color: "#fff",
    "&:hover": { color: "#000" },
  },
  playerCardWrapper: {
    width: "fit-content",
    position: "relative",
  },
  slotConfigContainer: {
    display: "flex",
    flexFlow: "column wrap",
    marginTop: 10,
    gap: 10,
    width: "100%",
  },
  slotConfigHeader: {
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 6,
  },
  slotContainer: {
    padding: 12,
    marginTop: 15,
    backgroundColor: `#D3D3D3${opacity2hex("0.35")}`,
    borderRadius: 12,
  },
  slotNumberText: {
    marginLeft: 8,
    fontSize: 12,
    fontWeight: "bold",
  },
  playerSlotContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 15,
  },
  boosterSlotContainer: {
    marginTop: 20,
    justifyContent: "flex-end",
    display: "flex",
    flexFlow: "row wrap",
    gap: 15,
  },
  buttonContainer: {
    display: "flex",
    gap: 10,
  },
  rowProbabilities: {
    display: "flex",
    flexFlow: "column wrap",
  },

  errorMessage: {
    color: "red",
    fontSize: 15,
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
  slotHeader: {
    marginBottom: 10,
  },

  slotEditor: {
    marginTop: 10,
  },
  slotHeaderText: {
    marginTop: 20,
    fontWeight: 700,
  },
  prevContainer: {
    display: "flex",
    flexFlow: "column nowrap"
  },
  preview: {
    aspectRatio: "16/9",
    height: "220px",
    objectFit: "contain",
    marginTop: 2,
  },
}));

export default useStyles;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Table from "components/Table";
import clubTablePosition from "utils/tableHelper/playerPosition";
import ContentShell from "components/ContentShell";
import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import configData from "config/config.json";
import { getPositions } from "services/playerCardService";
import usePaginationHook from "../../../hooks/usePagination";
import { opacity2hex } from "utils/miscUtils";
import SearchInput from "components/SearchInput";
import sessionHelper from "services/sessionHelper";
import { PermittedRoles } from "utils/permissions";
import { useSnackbar } from "hooks/useSnackbar";

const useStyles = makeStyles((theme) => ({
  savecancel: {
    width: "160px",
    height: 40,
  },
  editButton: {
    backgroundColor: theme.palette.common.offWhite + opacity2hex("0.80"),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  playerCardParent: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  searchInput: {
    width: "270px",
    marginBottom: 20,
  },
}));

const RowActions = ({ rowId, data }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const handleRowAction = (data) => {
    navigate(
      configData.routes.playerCard.editPosition.replace(":id", data.id),
      { state: { positionData: data } }
    );
  };

  return (
    <Button className={styles.editButton} onClick={() => handleRowAction(data)}>
      Edit
    </Button>
  );
};

const defaultPageSize = 10;
const defaultPageNo = 1;

const PositionsList = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const handleCreatePosition = () => {
    navigate(configData.routes.playerCard.createPosition);
  };
  const styles = useStyles();
  const [columns, setColumns] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.POSITION.UPDATE_ROLES
  );

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({ defaultPageNo, defaultPageSize });

  const fetchData = async (search) => {
    setLoading(true);
    try {
      const response = await getPositions({ pageNo, pageSize, search });
      const data = response.data.positions.map((item) => {
        const cleanedItem = Object.keys(item).reduce((acc, key) => {
          acc[key] = item[key] ?? "---";
          return acc;
        }, {});
        return cleanedItem;
      });
      showSnackbar(response.message, "success");
      setFilteredList(data);
      setLoading(false);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
      console.error("Error fetching position data: ", error);
    }
  };

  const onSearch = (value) => {
    setSearchValue(value);
    onPageSizeChange({ target: { value: defaultPageSize } });
    fetchData(value);
  };

  useEffect(() => {
    const initialColumns = clubTablePosition().filter(
      (col) => col.id !== "actions" || hasEditPermission
    );
    setColumns(initialColumns);

    fetchData();
  }, [pageNo, pageSize, hasEditPermission]);

  return (
    <ContentShell
      loading={loading}
      title="Positions"
      actions={
        <>
          <SearchInput
            value={searchValue}
            placeholder="Search player card"
            onSearch={onSearch}
          />
          {sessionHelper.hasRole(PermittedRoles.POSITION.CREATE_ROLES) && (
            <Button
              type="submit"
              variant="contained"
              className={styles.savecancel}
              onClick={handleCreatePosition}
            >
              Create Position
            </Button>
          )}
        </>
      }
    >
      <Box className={styles.playerCardParent}>
        <Table
          columns={columns}
          data={filteredList}
          currentPage={pageNo}
          actions={RowActions}
          pageSize={pageSize}
          totalEntries={filteredList.length}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
          highlightAltRow={true}
        />
      </Box>
    </ContentShell>
  );
};

export default PositionsList;

import React from "react";
import { useField } from "formik";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import FormInputShell from "./FormInputShell";
import { InputShell } from "./FormInputShell";
import dayjs from "dayjs";

const SimpleDateInput = ({
  name,
  value,
  readOnly,
  onChange,
  removeBottomMargin,
  ...props
}) => {
  return (
    <InputShell name={name} label={props.label} removeBottomMargin>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {readOnly ? (
          <Typography variant="body1" gutterBottom>
            {moment(value).format("DD MM, YYYY")}
          </Typography>
        ) : (
          <DatePicker
            value={dayjs(value)}
            variant="inline"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            // views={["day", "month", "year"]}
            readOnly={readOnly}
            disabled={readOnly}
            onChange={onChange}
            slotProps={{ textField: { size: "small" } }}
            renderInput={(props) => (
              <TextField {...props} style={{ width: "100%" }} />
            )}
            InputProps={{
              style: {
                height: 38,
                width: "100%",
              },
            }}
            {...props}
            label={""}
          />
        )}
      </LocalizationProvider>
    </InputShell>
  );
};

const DateInput = ({ label, readOnly, ...props }) => {
  const [field, meta, helper] = useField(props);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {readOnly ? (
        <Typography variant="body1" gutterBottom>
          {moment(field.value).format("DD MMM, YYYY")}
        </Typography>
      ) : (
        <DatePicker
          autoOk
          fullWidth
          mask=""
          variant="inline"
          error={meta.touched && !!meta.error}
          inputVariant="outlined"
          inputFormat="dd/MM/yyyy"
          readOnly={readOnly}
          disabled={readOnly}
          renderInput={(props) => (
            <TextField {...props} style={{ width: "100%" }} />
          )}
          InputProps={{
            style: {
              height: 38,
              width: "100%",
            },
          }}
          onClose={() => helper.setTouched(true)}
          {...field}
          {...props}
          onChange={(date) => {
            helper.setValue(date);
          }}
        />
      )}
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.touched && meta.error}
      </FormHelperText>
    </LocalizationProvider>
  );
};

const FormDateInput = (props) => (
  <FormInputShell label={props.label} name={props.name}>
    <DateInput {...props} />
  </FormInputShell>
);

FormDateInput.propTypes = DatePicker.propTypes;

export default FormDateInput;
export { DateInput, SimpleDateInput };

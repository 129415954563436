import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { SimpleDateInput } from "./FormDateInput";
import { Button } from "@mui/material";
import { SimpleSelectInput } from "./FormSelectInput";
import { SimpleTextInput } from "./FormTextInput";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "fit-content",
    // marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  field: {
    width: 180,
    margin: "0 8px 0 8px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 0 8px 0",
    },
  },
  buttonContainer: {
    minWidth: 90,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 24,
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      minWidth: "100%",
      paddingTop: 12,
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textTransform: "none",
    width: "100% !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      maxWidth: "100% !important",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  contentShell: {
    // overflow: "auto",
    // overflowX: "hidden",
    maxHeight: "100vh",
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 64,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  headerWithBackButton: {
    display: "flex",
    alignItems: "center",
  },
  titleWrapper: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  actionsWrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  container: {
    maxWidth: "100vw",
    boxSizing: "border-box",
    minHeight: "90vh",
    // overflow: "auto",
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  loadingUiWrapper: {
    width: "100%",
    height: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: 550,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  backButton: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "0px",
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  backArrow: {
    fontSize: "15px",
  },
}));

function ContentShell({
  loading,
  title,
  actions,
  filters,
  children,
  showBackButton,
  onBackButtonClick,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const renderFilterComp = (item) => {
    const { fieldType, componentProps } = item;
    switch (fieldType) {
      case "text":
        return <SimpleTextInput {...componentProps} />;
      case "date":
        return <SimpleDateInput {...componentProps} />;
      case "select":
        return <SimpleSelectInput {...componentProps} />;
      default:
        return <p>Invalid Field Type</p>;
    }
  };

  const _goBack = () => navigate(-1);

  return (
    <Box className={classes.contentShell}>
      {loading ? (
        <Box className={classes.loadingUiWrapper}>
          <CircularProgress />
        </Box>
      ) : (
        <Container className={classes.container}>
          <Box
            className={classNames(classes.header, {
              [classes.headerWithBackButton]: showBackButton,
            })}
          >
            {showBackButton && (
              <Box
                onClick={() =>
                  onBackButtonClick != null ? onBackButtonClick() : _goBack()
                }
              >
                <IconButton size={"large"} aria-label="delete">
                  <ArrowBackIosIcon className={classes.backArrow} />
                </IconButton>
              </Box>
            )}
            <Box className={classes.titleWrapper}>
              {typeof title === "string" ? (
                <Typography className={classes.title}>{title}</Typography>
              ) : (
                title
              )}
            </Box>

            <Box className={classes.actionsWrapper}>{actions}</Box>
          </Box>
          {filters && filters?.data?.length && (
            <Box className={classes.root}>
              {filters?.data?.map(
                (item, key) =>
                  !item.isHidden && (
                    <Box
                      key={key}
                      className={classes.field}
                      style={{ marginLeft: "0px" }}
                    >
                      {renderFilterComp(item)}
                    </Box>
                  )
              )}

              <Box className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  onClick={filters?.onSubmitClick}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          )}
          <Box>{children}</Box>
        </Container>
      )}
    </Box>
  );
}

ContentShell.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  actions: PropTypes.node,
  showBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
};

export default ContentShell;

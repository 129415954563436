import { makeStyles } from "@mui/styles";
import { opacity2hex } from "utils/miscUtils";

const useStyles = makeStyles((theme) => ({
  addRemoveContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 2,
    padding: 6,
  },
  dropProbabilities: {
    marginLeft: 8,
    display: "flex",
    flexFlow: "row wrap",
    gap: 2,
  },
  smallField: {
    width: 120,
  },
  hide: {
    display: "none !important",
  },
  namedPlayerDetailsContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 5,
  },
  additionalDataContainer: {
    height: 40,
    minWidth: 120,
    padding: "0 10px",
    borderRadius: 6,
    border: "1px solid grey",
    display: "flex",
    alignItems: "center",
  },
  additionDetailsLabel: {
    fontSize: 13,
    color: "#000000" + opacity2hex("0.50")
  },
  additionDetailsText: {
    fontSize: 14,
    color: "#0000d3" + opacity2hex("0.70"),
  },
}));

export default useStyles;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ResponsiveProvider } from "components/ResponsiveContext";
import {
  LoadingOverlayProvider,
  useLoadingOverlay,
} from "components/LoadingOverlay";
import { SnackbarProvider } from "hooks/useSnackbar";
import Home from "./Home";
import SignIn from "./SignIn";
import theme from "theme";
import sessionHelper from "services/sessionHelper";
import { signOut, restore } from "services/authService";
import configData from "config/config.json";

const muiTheme = createTheme(theme);

export const PrivateRoute = ({ children, requiredRole }) => {
  const isLoggedIn = sessionHelper.isLoggedIn();

  if (!isLoggedIn) {
    return <Navigate to={configData.routes.signIn} />;
  }

  if (requiredRole && !sessionHelper.hasRole(requiredRole)) {
    return <Navigate to={configData.routes.accessDenied} />;
  }

  return children;
};

const SessionHelper = ({ children }) => {
  const navigate = useNavigate();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (sessionHelper.isLoggedIn()) {
      showLoadingOverlay();
      restore()
        .then(async (result) => {
          // possible error checking the refresh token.
          // better to logout and sign in again.
          if (result.error) {
            await signOut();
            navigate(configData.routes.signIn);
          }
        })
        .finally(() => {
          setLoading(false);
          hideLoadingOverlay();
        });
    } else {
      setLoading(false);
    }
  }, []);

  return loading ? null : <>{children}</>;
};

const Screens = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <ResponsiveProvider>
        <SnackbarProvider>
          <LoadingOverlayProvider>
            <HashRouter>
              <SessionHelper>
                <Routes>
                  <Route path={configData.routes.signIn} element={<SignIn />} />
                  <Route path={configData.routes.home} element={<Home />} />
                </Routes>
              </SessionHelper>
            </HashRouter>
          </LoadingOverlayProvider>
        </SnackbarProvider>
      </ResponsiveProvider>
    </ThemeProvider>
  );
};

export default Screens;

import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormInputShell, { InputShell } from "./FormInputShell";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  menu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  subLabel: {
    margin: 0,
    padding: 0,
    fontSize: 9,
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  hideElement: {
    display: "none",
  },
  label: {
    margin: 0,
    padding: 0,
    fontSize: 16,
    // fontWeight: 600,
  },
  _label: {
    fontSize: "12px !important",
  },
  typography: {
    fontSize: 14,
    padding: 10,
    border: "1px solid grey",
    borderRadius: 5,
    color: theme.palette.common.grey,
  },
}));


const SimpleTextInput = ({ label, readOnly, readonlyvalue,customStyle, ...props }) => {
  const classes = useStyles()
  return (
    <InputShell label={label} name={props.name}>
      {readOnly ? (
        <Typography className={classes.typography}>{readonlyvalue || ""}</Typography>
      ) : (
        <TextField
          variant="outlined"
          disabled={props.readOnly}
          inputProps={{ readOnly: props.readOnly }}
          fullWidth
          rows={props.multiline ? 4 : 1}
          {...props}
          InputProps={{
            style: {
              height: 38,
              ...customStyle
            },
          }}
        />
      )}
    </InputShell>
  );
};

const TextInput = (props) => {
  const [field, meta] = useField(props);
  const { readOnly } = props;

  return readOnly ? (
    <Typography variant="body1" gutterBottom>
      {field.value}
    </Typography>
  ) : (
    <TextField
      variant="outlined"
      disabled={readOnly}
      inputProps={{ readOnly }}
      fullWidth
      error={!!(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
      rows={props.multiline ? 4 : 1}
      rowsmax={props.multiline ? Infinity : 1}
      {...field}
      {...props}
      InputProps={{
        style: {
          height: 38,
        },
      }}
    />
  );
};

const FormTextInput = ({ label, ...props }) => {
  return (
    <FormInputShell label={label} name={props.name}>
      <TextInput {...props} />
    </FormInputShell>
  );
};

FormTextInput.propTypes = TextField.propTypes;

export default FormTextInput;
export { TextInput, SimpleTextInput };

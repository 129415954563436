export const platform = "65841448b4d5c2c9b6c89e0f";
export const operator = "658414bd15aca9d59aa36fd4";
export const brand = "6584156ea9ff07043a0efc82";
export const defaultCurrencyRate = "USD";

export const RoundStatus = [
  { label: "All", id: "ALL" },
  { label: "DEBIT_COMPLETED", id: "debit_completed" },
  { label: "CREDIT_COMPLETED", id: "credit_completed" },
  { label: "REFUND_COMPLETED", id: "refund_completed" },
  { label: "DEBIT_FAILED", id: "debit_failed" },
  { label: "CREDIT_FAILED", id: "credit_failed" },
  { label: "REFUND_FAILED", id: "refund_failed" },
];

export const wrapWithAll = (array) => [{ id: "ALL", label: "All" }, ...array];

export const CurrencyOptions = [
  { label: "INR", id: "INR" },
  { label: "EUR", id: "EUR" },
  { label: "USD", id: "USD" },
];

export const roles = [
  { id: "SUPER_ADMIN", label: "SUPER_ADMIN" },
  { id: "SUB_ADMIN", label: "SUB_ADMIN" },
  { id: "MANAGER", label: "MANAGER" },
  { id: "SUPPORT", label: "SUPPORT" },
];

export const PBO_STR = ["platform", "operator", "brand"];
export const DEFAULT_VARIANT = "inline";

export const GAME_TYPES = [
  { label: "Provably Games", id: "pg" },
  { label: "Slot Games", id: "sg" },
  { label: "Table game", id: "tg" },
];

export const defaultFontFamily = "Open Sans, sans-serif";

export const editPlayerPositions = [
  { value: "Attack", label: "Attack" },
  { value: "Midfield", label: "Midfield" },
  { value: "Defence", label: "Defence" },
  { value: "Goalkeeper", label: "Goalkeeper" }
]

export const userRoles = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  SUPPORT: 'SUPPORT',
}
/* eslint-disable react-hooks/exhaustive-deps */
import ContentShell from "components/ContentShell";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import configData from "config/config.json";
import { useSnackbar } from "hooks/useSnackbar";
import useStyles from "styles/operator/operatorEditor";
import { SimpleTextInput } from "components/FormTextInput";
import { SimpleSelectInput } from "components/FormSelectInput";
import { create, getOperatorInfo, update } from "services/operatorService";
import sessionHelperInstance from "services/sessionHelper";

const _operatorDetails = {
  operatorName: "",
  platform: "",
};

const OperatorEditor = () => {
  const styles = useStyles();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [operatorDetails, setOperatorDetails] = useState(_operatorDetails);

  const cancel = () => navigate(configData.routes.operators.operators);

  const submit = async () => {
    try {
      setLoading(true);
      let response;
      if (id === "create") {
        response = await create(operatorDetails);

        // append new data to session helper
        sessionHelperInstance.updatePBO(response?.data, "operator");
      } else {
        response = await update(
          {
            operatorName: operatorDetails.operatorName,
            platform: operatorDetails.platform,
          },
          id
        );
      }

      showSnackbar(response.message, "success");
      setLoading(false);
      navigate(configData.routes.operators.operators);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e?.target;
    setOperatorDetails((state) => ({ ...state, [name]: value }));
  };

  const fetchOperatorInfo = async () => {
    try {
      setLoading(true);
      const resp = await getOperatorInfo(id);
      setOperatorDetails(resp.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  useEffect(() => {
    if (id !== "create") fetchOperatorInfo();
  }, []);

  return (
    <ContentShell
      showBackButton
      onBackButtonClick={cancel}
      title={`${id === "create" ? "Create" : "Edit"} Operator`}
      loading={loading}
      actions={
        <Box className={styles.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={submit}>
            Save
          </Button>
        </Box>
      }
    >
      <Box className={styles.container}>
        <Box className={styles.firstFormContainer}>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Operator Name"
              name="operatorName"
              value={operatorDetails.operatorName}
              onChange={onChange}
            />
          </Box>

          <Box className={styles.field}>
            <SimpleSelectInput
              readonlyvalue={
                sessionHelperInstance.platformOptions.find(
                  (el) => el.id === operatorDetails.platform
                )?.label
              }
              label="Platform"
              name="platform"
              value={operatorDetails.platform}
              options={sessionHelperInstance.platformOptions}
              onChange={onChange}
            />
          </Box>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default OperatorEditor;

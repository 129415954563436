class SessionHelper {
  email = null;
  role = null;
  token = null;
  countries = null;
  countryOptions = null;
  ovrFormulas = null;
  platformOptions = [];
  operatorOptions = [];
  brandOptions = [];

  onSignIn({ email, name, role, sid, isActive, id, lastLogin }) {
    this.email = email;
    this.role = role;
    this.token = sid;

    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        token: sid,
        email,
        role,
        name,
        isActive,
        id,
        lastLogin,
      })
    );
  }

  onCreateSession(details) {
    const { countries, ovrFormula, platforms, operators, brands } = details;

    this.countries = countries;
    this.countryOptions = countries?.map((country) => ({
      id: country?.name,
      label: country?.name,
    }));

    this.ovrFormulas = ovrFormula;
    this.platformOptions = platforms?.map((item) => ({
      id: item._id,
      label: item.platformName,
    }));
    this.operatorOptions = operators?.map((item) => ({
      id: item._id,
      platform: item?.platform,
      label: item.operatorName,
    }));
    this.brandOptions = brands?.map((item) => ({
      id: item._id,
      platform: item?.platform,
      operator: item?.operator,
      label: item.brandName,
    }));
  }

  onSignOut() {
    this.email = null;
    this.role = null;
    this.token = null;
  }

  setUserInfo() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.token = userInfo?.token;
    this.email = userInfo?.email;
    this.role = userInfo?.role;
  }

  hasRole(requiredRole) {
    return Array.isArray(requiredRole)
      ? requiredRole.includes(this.role)
      : this.role === requiredRole;
  }

  isLoggedIn() {
    return Boolean(localStorage.getItem("userInfo"));
  }

  updatePBO(data, type) {
    let _data = [];
    if (!type && !data) return;

    if (type === "platform") {
      _data = [
        ...this.platformOptions,
        { id: data?._id, label: data?.platformName },
      ];
    } else if (type === "operator") {
      _data = [
        ...this.operatorOptions,
        {
          id: data?._id,
          platform: data?.platform,
          label: data?.operatorName,
        },
      ];
    } else if (type === "brand") {
      _data = [
        ...this.brandOptions,
        {
          id: data?._id,
          platform: data?.platform,
          operator: data?.operator,
          label: data?.brandName,
        },
      ];
    }

    this[`${type}Options`] = _data;
  }
}

const sessionHelperInstance = new SessionHelper();
export default sessionHelperInstance;

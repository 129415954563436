import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import FormInputShell, { InputShell } from "./FormInputShell";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  menu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  subLabel: {
    margin: 0,
    padding: 0,
    fontSize: 9,
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  hideElement: {
    display: "none",
  },
  label: {
    margin: 0,
    padding: 0,
    fontSize: 14,
    // fontWeight: 600,
  },
  _label: {
    fontSize: "12px !important",
  },
  typography: {
    fontSize: 14,
    padding: 8,
    border: "1px solid grey",
    borderRadius: 5,
    color: theme.palette.common.grey,
  },
}));

const SimpleSelectInput = ({ label, readOnly, customStyle, ...props }) => {
  const classes = useStyles();

  return (
    <InputShell label={label} name={props.name}>
      {readOnly ? (
        <Typography className={classes.typography} variant="body1" gutterBottom>
          {props?.readonlyvalue || ""}
        </Typography>
      ) : (
        <Select
          disabled={props.readOnly}
          variant="outlined"
          defaultValue={"none"}
          fullWidth
          {...props}
          style={{ height: 38, ...customStyle}}
        >
          <MenuItem disabled value="none">
            <em style={{ color: "gray", fontWeight: 500, fontStyle: "normal" }}>
              {props?.placeholdertext || `Select ${label ?? ''}`}
            </em>
          </MenuItem>
          {props?.options?.map((option) => (
            <MenuItem className={classes.menu} key={`${option.id} - ${option.label}`} value={option.id}>
              <p
                className={classNames(classes?.subLabel, {
                  [classes.hideElement]: !option.subLabel,
                })}
              >
                {option?.subLabel}
              </p>

              <p
                className={classNames(classes.label, {
                  [classes._label]: option?.subLabel,
                })}
              >
                {" "}
                {option.label}
              </p>
            </MenuItem>
          ))}
        </Select>
      )}
    </InputShell>
  );
};

const SelectInput = ({ options, readOnly, ...props }) => {
  const [field, meta] = useField(props);
  const classes = useStyles();
  return (
    <>
      {readOnly ? (
        <Typography className={classes.typography} variant="body1" gutterBottom>
          {props.readonlyvalue}
        </Typography>
      ) : (
        <Select
          disabled={readOnly}
          variant="outlined"
          fullWidth
          error={meta.touched && !!meta.error}
          {...field}
          {...props}
          style={{ height: 38 }}
        >
          {options.map((option) => (
            <MenuItem
              className={classes.menu}
              key={option.id}
              value={option.id}
            >
              <p
                className={classNames(classes?.subLabel, {
                  [classes.hideElement]: !option.subLabel,
                })}
              >
                {option?.subLabel}
              </p>

              <p
                className={classNames(classes.label, {
                  [classes._label]: option?.subLabel,
                })}
              >
                {" "}
                {option.label}
              </p>
            </MenuItem>
          ))}
        </Select>
      )}
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.touched && meta.error}
      </FormHelperText>
    </>
  );
};

const FormSelectInput = ({ label, ...props }) => (
  <FormInputShell label={label} name={props.name}>
    <SelectInput {...props} />
  </FormInputShell>
);

FormSelectInput.defaultProps = {
  options: [],
};

FormSelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  ...Select.propTypes,
};

export default FormSelectInput;
export { SelectInput, SimpleSelectInput };

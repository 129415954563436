const getLeagueTableColumns = (options) => {
  return [
    {
      id: "name",
      label: `Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "number",
      label: `League Number`,
      type: "number",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "k1",
      label: `K1`,
      type: "number",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "k2",
      label: `K2`,
      type: "number",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "isDisabled",
      label: `Disabled`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "createdAt",
      label: `Created At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "updatedAt",
      label: `Updated At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    { id: "actions", label: "Actions" },
  ];
};

export { getLeagueTableColumns };

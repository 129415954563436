const { Client } = require("./_clientService");

async function getLeagues(options) {
  try {
    let payload = {
      pageNo: options?.pageNo,
      limit: options?.pageSize,
      sortKey: "createdAt",
      sortDir: "ASC",
      search: options?.searchValue,
      skipPagination: options?.skipPagination ?? false,
    };

    let path = "/league/list";
    const resp = await new Client({ path, payload }).post();

    return resp;
  } catch (error) {
    throw error;
  }
}

function leagueInfo(leagueId) {
  try {
    const resp = new Client({
      path: "/league/info",
      payload: {
        leagueConfigId: leagueId,
      },
    }).post();
    return resp;
  } catch (error) {
    throw error;
  }
}

function createLeagueAndMileStone(leagueDetails) {
  try {
    const resp = new Client({
      path: "/league/create",
      payload: leagueDetails,
    }).post();
    return resp;
  } catch (error) {
    throw error;
  }
}

function updateLeagueAndMileStone(leagueDetails, leagueId) {
  try {
    const resp = new Client({
      path: "/league/update",
      payload: { ...leagueDetails, _id: leagueId },
    }).patch();
    return resp;
  } catch (error) {
    throw error;
  }
}

export {
  getLeagues,
  leagueInfo,
  createLeagueAndMileStone,
  updateLeagueAndMileStone,
};

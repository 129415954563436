import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    height: 35,
    width: 35,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  pageTextStyle: {
    color: "white",
    fontSize: 14,
  },
}));

const PageSize = [
  { id: 10, label: 10 },
  { id: 25, label: 25 },
  { id: 50, label: 50 },
];

const Pagination = ({
  currentPage,
  totalEntries,
  pageSize,
  onChangePage: _onChangePage,
  onRowsPerPageChange,
}) => {
  const classes = useStyles();
  const onChangePage = (_, newPage) => _onChangePage(newPage);

  if (typeof currentPage !== "number") return null;

  // if (totalEntries < pageSize) return null;

  return (
    <Box
      style={{ padding: "10px 0", display: "flex", justifyContent: "center" }}
    >

      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <InputLabel
          variant="standard"
          style={{ fontSize: 14, marginRight: 12 }}
        >
          Rows per page:
        </InputLabel>
        <Select
          variant="standard"
          disableUnderline={true}
          style={{ border: 0, outline: "none", height: 24 }}
          value={pageSize}
          onChange={onRowsPerPageChange}
        >
          {PageSize.map((option) => (
            <MenuItem key={`${option.id} - ${option.label}`} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* totalEntries is required for Mui */}
      {/* <MuiPagination
        color="secondary"
        page={currentPage}
        count={Math.ceil(totalEntries / pageSize)}
        component="div"
        onChange={onChangePage}
      /> */}

      <div style={{ display: "flex", flexFlow: "row nowrap" }}>
        <Button
          style={{ fontWeight: "bold" }}
          disabled={currentPage === 1}
          onClick={() => onChangePage("", currentPage - 1)}
        >
          &lt;
        </Button>
        <div className={classes.pageContainer}>
          <Typography className={classes.pageTextStyle}>{`${currentPage}`}</Typography>{" "}
        </div>
        <Button
          style={{ fontWeight: "bold" }}
          disabled={totalEntries < pageSize}
          onClick={() => onChangePage("", currentPage + 1)}
        >
          &gt;
        </Button>
      </div>
    </Box>
  );
};

Pagination.defaultProps = {
  rows: [],
  onChangePage: () => {},
  totalEntries: 0,
  pageSize: 10,
};

Pagination.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onChangePage: PropTypes.func,
};

export default Pagination;

import React from "react";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    width: "100%",
    height: "100%",
    position: "relative",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: 0,
    },
  },
  paper: {
    // border: `2px solid ${theme.palette.border.default}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    width: "25px",
    zIndex: 100,
    cursor: "pointer",
    marginRight: 12,
    marginTop: 12,
  },
  drawerContainer: {
    width: "100%",
    backgroundColor: "white",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
}));

const DrawerContainer = ({ children, toggle }) => {
  const classes = useStyles();

  return (
    <div className={classes.drawerContainer}>
      <span className={classes.closeButton}>
        <CloseIcon style={{ fontSize: 24 }} onClick={toggle} />
      </span>
      {children}
    </div>
  );
};

const ModalContainer = ({ openModel, handleClose, children, ...props }) => {
  const classes = useStyles();
  const isMobileView = window.innerWidth < 900;

  return (
    <>
      {!isMobileView ? (
        <Modal
          className={classes.modal}
          open={openModel}
          onClose={handleClose}
          BackdropProps={{ timeout: 500 }}
          style={{ margin: 8 }}
        >
          <Fade in={openModel}>
            <div className={classes.paper}>
              <Box className={classes.root}>
                <span className={classes.closeButton}>
                  <CloseIcon style={{ fontSize: 24 }} onClick={handleClose} />
                </span>
                {children}
              </Box>
            </div>
          </Fade>
        </Modal>
      ) : (
        <Drawer anchor={"bottom"} open={openModel} onClose={handleClose}>
          <DrawerContainer toggle={handleClose}>{children}</DrawerContainer>
        </Drawer>
      )}
    </>
  );
};

export default ModalContainer;

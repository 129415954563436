/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ColorPicker from "components/ColorPicker";
import ContentShell from "components/ContentShell";
import React, { useEffect, useState } from "react";
import PlayerCardCanvas from "components/PlayerCardCanvas";
import { sendMessageToCanvas } from "utils/miscUtils";
import { SimpleTextInput } from "components/FormTextInput";
import { useSnackbar } from "hooks/useSnackbar";
import {
  updateClub,
  getInfoClub,
  createClub,
  jerseySizeOptions,
  JERSY_COLOR_TYPE_OPTIONS,
} from "services/playerCardService";
import { useLocation } from "react-router-dom";
import { SimpleSelectInput } from "components/FormSelectInput";
import { defaultCardDetails } from "config/playerCardDetails";

const useStyles = makeStyles((theme) => ({
  submitButton: {
    width: 230,
    backgroundColor: "#1D1030",
    color: "white",
    fontWeight: "bold",
    marginTop: 10,
  },
  playerClubEditor: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    gap: "25px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
      gap: 25,
    },
    [theme.breakpoints.up("sm")]: {
      gap: 30,
    },
  },
  clubSelectionContainer: {
    position: "relative",
  },
  clubForm: {
    marginTop: -5,
  },
  editButton: {
    width: "100%",
    backgroundColor: "#1D1030",
    color: "white",
    fontWeight: "bold",
  },
}));

const EditClub = () => {
  const location = useLocation();
  const id = location.pathname.split("/").slice(-1)[0];
  const [loading, setLoading] = useState(true);
  const [playerDetails, setPlayerDetails] = useState({
    ...defaultCardDetails,
    clubName: "",
    clubAbbrName: "",
  });
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();

  const sendToCanvas = (type, data) => {
    const iframe = document.getElementById("playerCard");
    if (iframe) {
      sendMessageToCanvas(iframe, {
        message: "UPDATE_PLAYER",
        type: type === "INIT" ? "INIT" : type,
        data: data,
      });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    let data = {
      ...playerDetails,
      [name]: value,
    };
    sendToCanvas(name, { value });
    setPlayerDetails(data);
  };

  const submit = async (e) => {
    setLoading(true);
    try {
      let response;
      let payload = {
        name: playerDetails.clubName,
        abbreviationName: playerDetails.clubAbbrName,
        textColor: playerDetails.clubTextColor,
        backgroundColor: playerDetails.clubBGColor,
        jerseyYokeColor: playerDetails.jerseyYokeColor,
        jerseyFrontColor: playerDetails.jerseyFrontColor,
        jerseySleevesColor: playerDetails.jerseySleevesColor,
        jerseyCollarColor: playerDetails.jerseyCollarColor,
        jerseySize: playerDetails.jerseySize,
        jerseyCollarType: playerDetails.collarType,
      };
      if (id === "create") {
        response = await createClub({ ...payload });
      } else {
        response = await updateClub(payload, id);
      }
      showSnackbar(response?.message ?? response?.details, "success");
    } catch (error) {
      showSnackbar("An error has occurred", "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      if (id !== "create") {
        const response = await getInfoClub(id);

        const { data } = response;

        setPlayerDetails((prevDetails) => ({
          ...prevDetails,
          clubName: data?.name,
          clubAbbrName: data?.abbreviationName,
          clubTextColor: data?.textColor,
          clubBGColor: data?.backgroundColor,
          jerseySize: data?.jerseySize,
          collarType: data?.jerseyCollarType,
          jerseyCollarColor: data?.jerseyCollarColor,
          jerseyFrontColor: data?.jerseyFrontColor,
          jerseySleevesColor: data?.jerseySleevesColor,
          jerseyYokeColor: data?.jerseyYokeColor,
        }));
      }
    } catch (error) {
      showSnackbar(error ?? error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContentShell
      showBackButton
      loading={loading}
      title={id === "create" ? "Create Club" : "Edit Club"}
    >
      <Box className={styles.playerClubEditor}>
        <Box>
          <Box className={styles.clubSelectionContainer}>
            <SimpleTextInput
              type="text"
              label="Club Name"
              name="clubName"
              value={playerDetails.clubName}
              onChange={onChange}
            />
          </Box>
          <br />
          <Box>
            <PlayerCardCanvas
              playerDetails={playerDetails}
              sendToCanvas={sendToCanvas}
            />
          </Box>
        </Box>

        <Box className={styles.clubForm}>
          <SimpleTextInput
            type="text"
            label="Club Abbreviation Name"
            name="clubAbbrName"
            value={playerDetails.clubAbbrName}
            onChange={onChange}
          />
          <SimpleSelectInput
            type="text"
            label="Jersey Size"
            name="jerseySize"
            options={jerseySizeOptions}
            value={playerDetails.jerseySize}
            onChange={onChange}
          />
          <SimpleSelectInput
            type="text"
            label="Coller Type"
            name="collarType"
            options={JERSY_COLOR_TYPE_OPTIONS}
            value={playerDetails.collarType}
            onChange={onChange}
          />
          <ColorPicker
            label={"Club Text Color"}
            name="clubTextColor"
            value={playerDetails.clubTextColor}
            onChange={onChange}
            customStyle={{ width: 230, marginBottom: 7, marginTop: 10 }}
          />
          <ColorPicker
            label={"Club Bg Color"}
            name="clubBGColor"
            value={playerDetails.clubBGColor}
            onChange={onChange}
            customStyle={{ width: 230, marginBottom: 7 }}
          />
          <ColorPicker
            label={"Jersey Yoke Color"}
            name="jerseyYokeColor"
            value={playerDetails.jerseyYokeColor}
            onChange={onChange}
            customStyle={{ width: 230, marginBottom: 7 }}
          />
          <ColorPicker
            label={"Jersey Front Color"}
            name="jerseyFrontColor"
            value={playerDetails.jerseyFrontColor}
            onChange={onChange}
            customStyle={{ width: 230, marginBottom: 7 }}
          />
          <ColorPicker
            label={"Jersey Sleeves Color"}
            name="jerseySleevesColor"
            value={playerDetails.jerseySleevesColor}
            onChange={onChange}
            customStyle={{ width: 230, marginBottom: 7 }}
          />
          <ColorPicker
            label={"Jersey Collar Color"}
            name="jerseyCollarColor"
            value={playerDetails.jerseyCollarColor}
            onChange={onChange}
            customStyle={{ width: 230 }}
          />
          <Button
            type="submit"
            variant="contained"
            className={styles.submitButton}
            onClick={submit}
          >
            {id === "create" ? "Create Club" : "Update Club"}
          </Button>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default EditClub;

import React from "react";
import { Link } from "@mui/material";
import { ResponsiveContext } from "./ResponsiveContext";
import logo from "assets/Icon_Coin.png"

function AppLogo({ logoPadding = "8px 0px 0px 0px" }) {
  const { isMobileView } = React.useContext(ResponsiveContext);
  return (
    <Link
      href={""}
      style={{
        textDecoration: "none",
        padding: logoPadding,
        cursor: "pointer",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        height={isMobileView ? "40px" : "40px"}
        width={isMobileView ? "40px" : "40px"}
        style={{ paddingLeft: 0, margin: isMobileView ? "0 6px 0 0" : 0 }}
      />
    </Link>
  );
}

export default AppLogo;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import debounce from "lodash.debounce";
import { styled, alpha } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.grey, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.grey, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#00000 !important",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const SearchInput = ({ value, placeholder = "Search...", onSearch }) => {
  const [query, setQuery] = useState(value);

  const debouncedSearch = useCallback(
    debounce((searchQuery) => onSearch(searchQuery), 500),
    [onSearch]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    debouncedSearch(value);
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon style={{ color: "#4d4d4d" }} />
      </SearchIconWrapper>
      <StyledInputBase
        fullWidth
        autoFocus
        placeholder={placeholder}
        value={query}
        onChange={handleInputChange}
      />
    </Search>
  );
};

export default SearchInput;

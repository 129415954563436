import sessionHelper from "./sessionHelper";

function Client({
  path = "",
  baseUrl = process.env.REACT_APP_CMS_API_URL,
  options = {},
  payload,
  headers = {},
  redirect = ""
}) {
  const _url = (() => {
    let _path = path;

    if (_path[0] !== "/") _path = "/" + _path;

    return baseUrl + _path;
  })();

  const _options = {
    ...JSON.parse(JSON.stringify(options)),
    headers: {
      Authorization: `Bearer ${sessionHelper?.token}`,
      "Content-Type": "application/json",
      ...headers,
    },
  };

  if (payload) {
    _options.body = JSON.stringify(payload);
  }

  if (redirect) _options['redirect'] = redirect

  const _handleError = (error) => {
    console.error(error.message);
    throw error;
  };

  const _fetch = async (...args) => {

    try {
      const response = await fetch(...args);
      const responseJson = await response?.json();

      if (response.status === 401) {
        sessionHelper.onSignOut();
      }

      if (![200, 201].includes(response.status)) {
        throw new Error(
          responseJson?.details ||
          responseJson?.message ||
          "Internal Server Error Occurred"
        );
      }

      return responseJson;
    } catch (error) {
      console.error("Fetch Error:", error.message); // Improved error message
      _handleError(error);
    }
  };

  /**
   * GET request to remote service
   */
  this.get = () =>
    _fetch(_url, {
      ..._options,
      method: "GET",
    });

  /**
   * POST request to remote service
   */
  this.post = () =>
    _fetch(_url, {
      ..._options,
      method: "POST",
    });

  /**
   * PUT request to remote service
   */
  this.put = () =>
    _fetch(_url, {
      ..._options,
      method: "PUT",
    });

  /**
   * PATCH request to remote service
   */
  this.patch = () =>
    _fetch(_url, {
      ..._options,
      method: "PATCH",
    });

  /**
   * DELETE request to remote service
   */
  this.delete = () =>
    _fetch(_url, {
      ..._options,
      method: "DELETE",
    });
}

export { Client };

function packListTableColumn() {
    return [
        {
            id: "name",
            label: `Name`,
            type: "string",
            isSortable: true,
            alignHead: "center",
            alignBody: "center",
        },
        {
            id: "type",
            label: `Type`,
            type: "string",
            isSortable: true,
            alignHead: "center",
            alignBody: "center",
        },
        {
            id: "summary",
            label: `Summary`,
            type: "string",
            isSortable: true,
            alignHead: "center",
            alignBody: "center",
        },
        {
            id: "isDisabled",
            label:`isDisabled`,
            type: "string",
            isSortable: true,
            alignHead: "center",
            alignBody: "center",
        },
        {
            id: "price",
            label: `Price`,
            type: "string",
            isSortable: true,
            alignHead: "center",
            alignBody: "center",
        },
      { id: "actions", label: "Actions" },
    ];
  }

  export {packListTableColumn};
  

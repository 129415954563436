/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Chip, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContentShell from "components/ContentShell";
import { InputShell } from "components/FormInputShell";
import { SimpleSelectInput } from "components/FormSelectInput";
import { SimpleTextInput } from "components/FormTextInput";
import { useLoadingOverlay } from "components/LoadingOverlay";
import PlayerCardCanvas from "components/PlayerCardCanvas";
import SearchableInput from "components/SearchableInput";
import { defaultCardDetails } from "config/playerCardDetails";
import { useSnackbar } from "hooks/useSnackbar";
import debounce from "lodash.debounce";
import { evaluate } from "mathjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CARD_TYPE_OPTIONS,
  createPlayerCard,
  getClubs,
  getPlayerCardTemplate,
  getPositions,
  jerseySizeOptions,
  JERSY_COLOR_TYPE_OPTIONS,
  playerCardInfo,
  updatePlayerCard,
  uploadPlayerCardImage,
} from "services/playerCardService";
import sessionHelper from "services/sessionHelper";
import { roundOff, sendMessageToCanvas } from "utils/miscUtils";

const useStyles = makeStyles((theme) => ({
  playerCardCanvas: {
    alignSelf: "center",
    height: 400,
    width: 260,
    padding: "6px 10px 10px 6px",
    borderRadius: "0.35rem",
    backgroundColor: "#dfdfdf",
  },
  playerCardEditor: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-evenly",
    gap: "10px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      gap: 25,
    },
    [theme.breakpoints.up("sm")]: {
      gap: 30,
    },
  },
  editorContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  field: {
    width: "100%",
  },
  submitButton: {
    width: "100%",
    backgroundColor: "#1D1030",
    color: "white",
    fontWeight: "bold",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  buttonContainer: {
    marginTop: 20,
    width: "100%",
  },
  downloadContainer: {
    marginTop: 20,
    display: "flex",
    flexFlow: "row wrap",
    gap: 10,
  },
  downloadButton: {
    fontSize: 12,
    width: "100%",
    maxWidth: 130,
  },
}));

const defaultPageSize = 20,
  defaultPageNo = 1;

const PlayerCardEditor = () => {
  const styles = useStyles();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();
  const [playerTemplates, setPlayerTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [playerDetails, setPlayerDetails] = useState({
    ...defaultCardDetails,
    clubAbbrName: "",
    clubName: "",
    position: "",
    positionName: "",
    playerTags: [],
  });
  const [searchLoader, setSearchLoader] = useState(false);
  const [positionsDetails, setPositionsDetails] = useState(null);
  const [clubsDetails, setClubDetails] = useState(null);
  const [options, setOptions] = useState({
    positionOptions: [],
    clubOptions: [],
  });
  const [images, setImages] = useState({
    large: null,
    mini: null,
  });
  const [playerTag, setPlayerTag] = useState("");
  const [searchPagination, setSearchPagination] = useState({
    pageNo: defaultPageNo,
    pageSize: defaultPageSize,
    hasMore: false,
  });

  const _getPlayerTemplates = async (options, isFetchMore = false) => {
    setSearchLoader(true);
    try {
      const response = await getPlayerCardTemplate({
        ...options,
        sortKey: "createdAt",
        sortDir: "DESC",
      });

      setPlayerTemplates((_state) =>
        isFetchMore ? [..._state, ...response.items] : response?.items
      );
      setSearchLoader(false);
      setSearchPagination((_pageData) => ({
        ..._pageData,
        hasMore: response.morePages,
      }));
    } catch (error) {
      setSearchLoader(false);
      showSnackbar(error.message);
    }
  };

  const _fetchMore = async () => {
    try {
      const response = await getPlayerCardTemplate({
        pageNo: searchPagination.pageNo + 1,
        pageSize: searchPagination.pageSize,
        sortKey: "createdAt",
        sortDir: "DESC",
      });

      const filteredData = response.items.filter((v) => {
        const isExist = playerTemplates.find((item) => item?._id === v?._id);

        if (isExist) return false;
        return true;
      });

      setPlayerTemplates((_state) => [..._state, ...filteredData]);
    } catch (error) {
      showSnackbar(error.message);
    }
  };

  const onSearchChange = async (searchValue, isFetchMore) => {
    setSearchLoader(true);
    try {
      if (searchValue !== "") {
        _getPlayerTemplates({
          ...searchPagination,
          search: searchValue,
          skipPagination: true,
        });
      } else {
        _getPlayerTemplates(searchPagination);
      }

      setSearchLoader(false);
    } catch (error) {
      setSearchLoader(false);
      showSnackbar(error?.message ?? error);
    }
  };

  const optimizeSearch = debounce(onSearchChange, 300);

  const handlePositionChange = ({
    fieldName,
    value,
    playerDetState,
    _positions,
  }) => {
    let data = playerDetState ? { ...playerDetState } : { ...playerDetails };
    let canvasPayload = {};
    const canvasEventname = "allPositionData";

    const _pos = positionsDetails
      ? positionsDetails?.find((el) => el.abbreviationName === value)
      : _positions?.find((el) => el.abbreviationName === value);

    let updatedPosData = {
      [fieldName]: value,
      textColor: _pos?.textColor ?? playerDetails.textColor,
      positionBGColor: _pos?.backgroundColor ?? playerDetails?.positionBGColor,
    };

    data = {
      ...data,
      ...updatedPosData,
    };

    canvasPayload = {
      value: updatedPosData,
    };

    return { data, canvasPayload, canvasEventname };
  };

  const handleCountryChange = ({ fieldName, value, playerDetState }) => {
    let data = playerDetState ? { ...playerDetState } : { ...playerDetails };
    let canvasPayload = {};
    const canvasEventname = "country";

    const _country = sessionHelper.countries?.find((el) => el.name === value);
    let updatedCountryData = {
      [fieldName]: value,
      imageUrl: _country.imageUrl,
    };

    data = {
      ...data,
      ...updatedCountryData,
    };

    canvasPayload = {
      value: updatedCountryData,
    };

    return { data, canvasPayload, canvasEventname };
  };

  const handleClubChange = ({ fieldName, value, playerDetState, _clubs }) => {
    let data = playerDetState ? { ...playerDetState } : { ...playerDetails };
    let canvasPayload = {};
    const canvasEventname = "allClubData";

    const _club = clubsDetails
      ? clubsDetails?.find((el) => el.name === value)
      : _clubs?.find((el) => el.name === value);
    let updatedClubData = {
      [fieldName]: value,
      clubName: _club?.name,
      jerseyFrontColor:
        _club?.jerseyFrontColor ?? playerDetails.jerseyFrontColor,
      jerseyYokeColor: _club?.jerseyYokeColor ?? playerDetails.jerseyYokeColor,
      jerseySleevesColor:
        _club?.jerseySleevesColor ?? playerDetails.jerseySleevesColor,
      jerseyCollarColor:
        _club?.jerseyCollarColor ?? playerDetails.jerseyCollarColor,
      clubTextColor: _club?.textColor ?? playerDetails.clubTextColor,
      clubBGColor: _club?.backgroundColor ?? playerDetails.clubBGColor,
      clubAbbrName: _club?.abbreviationName ?? playerDetails.clubAbbrName,
      jerseySize: _club?.jerseySize ?? playerDetails.jerseySize,
      collarType: _club?.jerseyCollarType ?? playerDetails.collarType,
    };

    data = {
      ...data,
      ...updatedClubData,
    };

    canvasPayload = {
      value: updatedClubData,
    };

    return { data, canvasPayload, canvasEventname };
  };

  const handleNumberFieldChange = ({ fieldName, value }) => {
    let data = { ...playerDetails };

    if (value < 1 || value > 99) {
      return {
        data,
        canvasPayload: { value: data[fieldName] },
        canvasEventname: fieldName,
      };
    }

    const updatedValue = {
      [fieldName]: Number(value?.split(".")[0]) ?? "",
    };

    data = {
      ...data,
      ...updatedValue,
    };

    return {
      data,
      canvasPayload: { value: data[fieldName] },
      canvasEventname: fieldName,
    };
  };

  const calculateRarity = (overallRating) => {
    if (overallRating < 70) {
      return "bronze";
    } else if (overallRating >= 70 && overallRating <= 79) {
      return "silver";
    } else if (overallRating > 79) {
      return "gold";
    }

    return "bronze";
  };

  const calculatePlayerOvr = ({
    preferredPosition,
    attackRating = 0,
    defenseRating = 0,
    creativityRating = 0,
  }) => {
    const formula = sessionHelper.ovrFormulas[preferredPosition];

    if (!formula) {
      return 0;
    }

    return roundOff(
      evaluate(formula, {
        ATT: attackRating,
        CRE: creativityRating,
        DEF: defenseRating,
      })
    );
  };

  const onChange = async (event) => {
    let { name, value } = event.target;
    let data = { ...playerDetails };

    let canvasPayload = { value: value ?? "" };
    let canvasEventname = name;

    const eventMap = {
      position: handlePositionChange,
      clubName: handleClubChange,
      country: handleCountryChange,
      attackRating: handleNumberFieldChange,
      defenceRating: handleNumberFieldChange,
      creativityRating: handleNumberFieldChange,
    };

    const eventHandler = eventMap[name];

    if (eventHandler) {
      const resp = eventHandler({ fieldName: name, value });

      data = resp.data;

      canvasPayload = resp.canvasPayload;

      canvasEventname = resp.canvasEventname;
    } else if (name === "selectedPlayer") {
      if (!value) {
        canvasPayload = {
          value: {
            ...playerDetails,
          },
        };
        return;
      }

      let _pTemplate = playerTemplates?.find((e) => e?.id === value?.id);
      setSelectedTemplate(_pTemplate); // set the _pTemplate for edit

      data = {
        ...data,
        position: _pTemplate?.preferredPosition,
        playerFaceImage: _pTemplate?.avatar,
        attackRating: _pTemplate?.attackRating,
        defenceRating: _pTemplate?.defenseRating,
        creativityRating: _pTemplate?.creativityRating,
        displayName: _pTemplate?.displayName,
        overallRating: _pTemplate?.overallRating,
        cardType: _pTemplate?.rarity,
        country: _pTemplate?.nationality,
        clubName: _pTemplate?.club,
        rarity: _pTemplate?.rarity,
        displayNameAbb: _pTemplate?.displayNameAbb,
      };

      const clubChangeResp = handleClubChange({
        fieldName: "clubName",
        value: _pTemplate?.club,
        playerDetState: data,
      });
      data = clubChangeResp.data;

      const posChangeResp = handlePositionChange({
        fieldName: "position",
        value: _pTemplate?.preferredPosition,
        playerDetState: data,
      });
      data = posChangeResp.data;

      const countryChangeResp = handleCountryChange({
        fieldName: "country",
        value: _pTemplate?.nationality,
        playerDetState: data,
      });
      data = countryChangeResp.data;

      canvasPayload = {
        value: {
          ...playerDetails,
          ...data,
          defaultAvatarUrl: _pTemplate?.defaultAvatarUrl,
          fullName: _pTemplate?.fullName ?? playerDetails?.fullName,
          commonName: _pTemplate?.commonName ?? playerDetails?.commonName,
          displayName: _pTemplate?.displayName ?? playerDetails?.displayName,
          displayNameAbb: _pTemplate?.displayNameAbb ?? "",
        },
      };
    } else if (name === "updatePlayer") {
      canvasPayload = {
        value: data,
      };
    } else {
      data = {
        ...data,
        [name]: value,
      };
    }

    sendToCanvas(
      canvasEventname === "updatePlayer" ? "selectedPlayer" : canvasEventname,
      canvasPayload
    );

    const newOvr = calculatePlayerOvr({
      preferredPosition: data.position,
      attackRating: data.attackRating,
      defenseRating: data.defenceRating,
      creativityRating: data.creativityRating,
    });

    if (newOvr !== data.overallRating) {
      sendToCanvas("overallRating", { value: newOvr });
      data.overallRating = newOvr;

      if (data.cardType !== "inForm") {
        data.cardType = calculateRarity(newOvr);
        data.rarity = data.cardType;

        sendToCanvas("cardType", { value: data.cardType });
      }
    }

    setPlayerDetails((prev) => ({ ...prev, ...data }));
  };

  const sendToCanvas = (type, data) => {
    const iframe = document.getElementById("playerCard");
    if (iframe) {
      sendMessageToCanvas(iframe, {
        message: "UPDATE_PLAYER",
        type: type === "INIT" ? "INIT" : type,
        data: data,
      });
    }
  };

  const submit = async () => {
    try {
      showLoadingOverlay();
      let response;
      if (id === "create") {
        let payload = {
          template: selectedTemplate?._id,
          ...playerDetails,
        };

        response = await createPlayerCard(payload);
        setPlayerDetails((prev) => ({
          ...prev,
          miniImageUploadUrl: response?.data?.miniImageUploadUrl,
          imageUploadUrl: response?.data?.imageUploadUrl,
        }));
      } else {
        response = await updatePlayerCard({
          cardId: id,
          ...selectedCard,
          ...playerDetails,
        });

        setPlayerDetails((prev) => ({
          ...prev,
          miniImageUploadUrl: response?.data?.miniImageUploadUrl,
          imageUploadUrl: response?.data?.imageUploadUrl,
        }));
      }

      if (response.data?.imageUploadUrl) {
        sendToCanvas("createPlayerCard", {});
      }

      showSnackbar(response?.message, "success");
      hideLoadingOverlay();
    } catch (error) {
      hideLoadingOverlay();
      showSnackbar(error.message, "error");
    }
  };

  const getCardInfo = async (_clubs, _positions) => {
    showLoadingOverlay();
    let details;
    try {
      const cardInfo = await playerCardInfo(id);
      details = {
        ...playerDetails,
        ...cardInfo?.extraData,
        cardType: cardInfo?.rarity,
        position: cardInfo?.preferredPosition,
        clubName: cardInfo?.club,
        country: cardInfo?.nationality,
        attackRating: cardInfo?.attackRating,
        creativityRating: cardInfo?.creativityRating,
        defenceRating: cardInfo?.defenseRating,
        overallRating: cardInfo?.overallRating,
        defaultAvatarUrl: cardInfo?.defaultAvatarUrl,
        fullName: cardInfo?.fullName,
        commonName: cardInfo?.commonName,
        displayName: cardInfo?.displayName,
        displayNameAbb: cardInfo?.displayNameAbb,
        playerTags: cardInfo.tags,
      };

      setSelectedCard(cardInfo);
      setPlayerDetails((prev) => ({ ...prev, ...details }));

      const clubChangeResp = handleClubChange({
        fieldName: "clubName",
        value: details.clubName,
        playerDetState: details,
        _clubs,
      });
      details = clubChangeResp.data;

      const posChangeResp = handlePositionChange({
        fieldName: "position",
        value: details.position,
        playerDetState: details,
        _positions,
      });
      details = posChangeResp.data;

      const countryChangeResp = handleCountryChange({
        fieldName: "country",
        value: details.country,
        playerDetState: details,
      });
      details = countryChangeResp.data;

      setTimeout(() => {
        sendToCanvas("selectedPlayer", {
          value: {
            ...details,
            playerFaceImage: details.defaultAvatarUrl,
          },
        });
      }, 1000);
    } catch (error) {
      showSnackbar(error?.details ?? error?.message ?? error, "error");
    } finally {
      hideLoadingOverlay();
    }
  };

  const fetchData = async () => {
    showLoadingOverlay();
    try {
      const [_positionsRes, _clubsRes] = await Promise.all([
        getPositions({ skipPagination: true }),
        getClubs({ skipPagination: true }),
      ]);

      let _positions = _positionsRes?.data?.positions;
      let _clubs = _clubsRes?.data?.clubs;

      setPositionsDetails(_positions);
      setClubDetails(_clubs);

      setOptions((prev) => ({
        ...prev,
        positionOptions: _positions?.map((el) => ({
          id: el?.abbreviationName,
          label: `${el?.abbreviationName}`, // (${el?.club})
        })),
        clubOptions: _clubs?.map((el) => ({
          id: el?.name,
          label: el?.name,
        })),
      }));
      hideLoadingOverlay();

      return { isLoaded: true, _clubs, _positions };
    } catch (error) {
      hideLoadingOverlay();
      showSnackbar("Failed to fetch positions and clubs");
    }
  };

  const uploadImages = async () => {
    try {
      if (!images?.mini && !images?.large) {
        return;
      }

      const imageUploadPromises = [];

      if (images.large) {
        imageUploadPromises.push(
          uploadPlayerCardImage({
            data: images.large,
            url: playerDetails.imageUploadUrl,
          })
        );
      }

      if (images.mini) {
        imageUploadPromises.push(
          uploadPlayerCardImage({
            data: images.mini,
            url: playerDetails.miniImageUploadUrl,
          })
        );
      }

      await Promise.all(imageUploadPromises);

      showSnackbar("Image Uploaded", "success");
    } catch (error) {
      showSnackbar("Error uploading image", "error");
    }
  };

  const handleCanvasImageData = async (event) => {
    if (event?.data?.messageName === "init") {
      const { isLoaded, _clubs, _positions } = await fetchData();
      if (id !== "create" && isLoaded)
        setTimeout(() => getCardInfo(_clubs, _positions), 0);
    } else if (event?.data?.messageName === "CanvasImageData") {
      setImages((prev) => ({
        ...prev,
        large: event?.data?.value[0],
        mini: event?.data?.value[1],
      }));
    }
  };

  const handleAddTag = (event) => {
    if ((event.key === "Enter" || event.key === ",") && playerTag.trim()) {
      if (!playerDetails.playerTags.includes(playerTag.trim())) {
        setPlayerDetails((state) => ({
          ...state,
          playerTags: [...state.playerTags, playerTag.trim()],
        }));
      }
      setPlayerTag("");
    }
  };

  const onPlayerTagChange = (e) => {
    const { value } = e.target;
    value !== "," && setPlayerTag(value);
  };

  const handleDeleteTag = (tagToDelete) => {
    setPlayerDetails((state) => ({
      ...state,
      playerTags: state.playerTags.filter((tag) => tag !== tagToDelete),
    }));
  };

  useEffect(() => {
    if (id === "create") _getPlayerTemplates(searchPagination);

    window.addEventListener("message", handleCanvasImageData);

    return () => {
      window.addEventListener("message", handleCanvasImageData);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => uploadImages(), 0);
  }, [images.large, images.mini]);

  return (
    <ContentShell
      showBackButton
      onBackButtonClick={() => navigate(-1)}
      title={`${id === "create" ? "Create" : "Edit"} Player Card`}
    >
      <Box className={styles.playerCardEditor}>
        <Box>
          <Box className={styles.field}>
            {id === "create" && (
              <SearchableInput
                name="selectedPlayer"
                options={playerTemplates?.map((el) => ({
                  id: el.id,
                  label: el.label,
                }))}
                label="Search"
                placeholder="Type to search..."
                onInputChange={optimizeSearch}
                onOptionSelect={onChange}
                customStyle={{ width: "100%" }}
                loading={searchLoader}
                fetchMore={_fetchMore}
                hasMore={searchPagination.hasMore}
              />
            )}
          </Box>
          <br />
          <Box className={styles.canvasContainer}>
            <PlayerCardCanvas
              playerDetails={playerDetails}
              sendToCanvas={sendToCanvas}
            />
          </Box>
        </Box>
        <Box style={{ display: "flex", flexFlow: "column" }}>
          <Box className={styles.editorContainer}>
            <Box className={styles.formContainer}>
              <SimpleSelectInput
                name="cardType"
                label="Choose Card Type"
                options={CARD_TYPE_OPTIONS}
                value={playerDetails.cardType || ""}
                onChange={onChange}
              />
              <SimpleSelectInput
                name="position"
                label="Choose Position"
                options={options.positionOptions}
                value={playerDetails.position}
                onChange={onChange}
              />
              <SimpleSelectInput
                name="clubName"
                label="Choose Club"
                value={playerDetails.clubName}
                options={options.clubOptions}
                onChange={onChange}
              />
              <SimpleSelectInput
                name="country"
                label="Choose Country"
                options={sessionHelper.countryOptions}
                value={playerDetails.country}
                onChange={onChange}
              />
              <SimpleTextInput
                type="text"
                label="Display Name"
                name="displayName"
                value={playerDetails.displayName}
                onChange={onChange}
              />
              <SimpleTextInput
                type="text"
                label="Display Name abb."
                name="displayNameAbb"
                value={playerDetails.displayNameAbb}
                onChange={onChange}
              />
            </Box>
            <Box className={styles.formContainer}>
              <SimpleTextInput
                type="number"
                label="Attack Points"
                name="attackRating"
                value={playerDetails.attackRating}
                onChange={onChange}
              />

              <SimpleTextInput
                type="number"
                label="Defence Points"
                name="defenceRating"
                value={playerDetails.defenceRating}
                onChange={onChange}
              />
              <SimpleTextInput
                type="number"
                label="Creativity Points"
                name="creativityRating"
                value={playerDetails.creativityRating}
                onChange={onChange}
              />

              <SimpleSelectInput
                name="jerseySize"
                label="Choose Jersey Size"
                options={jerseySizeOptions}
                value={playerDetails.jerseySize}
                onChange={onChange}
              />
              <SimpleSelectInput
                name="collarType"
                label="Jersey - Collar Type"
                options={JERSY_COLOR_TYPE_OPTIONS}
                value={playerDetails.collarType || ""}
                onChange={onChange}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  minWidth: 180,
                  maxWidth: 220,
                }}
              >
                <InputShell
                  label="Player Tags (Press Comma or Enter to add)"
                  name="playerTags"
                >
                  <TextField
                    name="playerTags"
                    variant="outlined"
                    size="small"
                    placeholder="Add Player Tags"
                    value={playerTag}
                    onChange={onPlayerTagChange}
                    onKeyDown={handleAddTag}
                    fullWidth
                  />
                </InputShell>
                <Box sx={{ display: "flex", flexFlow: "row wrap", gap: 1 }}>
                  {playerDetails.playerTags.map((tag, index) => (
                    <Chip
                      size="small"
                      key={index}
                      label={tag}
                      onDelete={() => handleDeleteTag(tag)}
                      color="primary"
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={styles.buttonContainer}>
            <Button
              type="submit"
              onClick={submit}
              variant="contained"
              className={styles.submitButton}
              disabled={!selectedTemplate && !selectedCard}
            >
              {id === "create" ? "Create " : "Update "}Player Card
            </Button>
          </Box>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default PlayerCardEditor;

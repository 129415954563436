/* eslint-disable react-hooks/exhaustive-deps */
import ContentShell from "components/ContentShell";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Divider, Typography } from "@mui/material";
import configData from "config/config.json";
import { useSnackbar } from "hooks/useSnackbar";
import useStyles from "styles/platform/platformEditor";
import { SimpleMultiSelectInput } from "components/FormMultiSelectInput";
import { SimpleTextInput } from "components/FormTextInput";
import { SimpleSelectInput } from "components/FormSelectInput";
import { create, getPlatformInfo, update } from "services/platformService";
import { deepCopy } from "utils/miscUtils";

const method = {
  url: "",
  method: "",
};
const _platformDetails = {
  platformName: "",
  betRequest: method,
  winRequest: method,
  balanceRequest: method,
  refundRequest: method,
  loginFromTokenRequest: method,
  completeGameRequest: method,
  voidGameRequest: method,
};

const defaultRequestMethods = [
  "betRequest",
  "winRequest",
  "balanceRequest",
  "refundRequest",
  "loginFromTokenRequest",
  "completeGameRequest",
  "voidGameRequest",
];

const PlatformEditor = () => {
  const styles = useStyles();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [platformDetails, setPlatformDetails] = useState(_platformDetails);
  const [options, setOptions] = useState({
    httpMethodOptions: [
      { id: "POST", label: "POST" },
      { id: "GET", label: "GET" },
      { id: "DELETE", label: "DELETE" },
      { id: "PUT", label: "PUT" },
      { id: "PATCH", label: "PATCH" },
    ],
    requiredMethods: [],
    requiredMethodOptions: defaultRequestMethods.map((e) => ({
      id: e,
      label: e.at(0).toUpperCase() + e.slice(1),
    })),
  });

  const cancel = () => navigate(configData.routes.platforms.platforms);

  const submit = async () => {
    try {
      setLoading(true);
      let response;
      if (id === "create") {
        response = await create(platformDetails);
      } else {
        const data = deepCopy(platformDetails);

        delete data._id;
        delete data.id;
        delete data.updatedAt;
        delete data.createdAt;

        response = await update(data, id);
      }

      showSnackbar(response.message, "success");
      setLoading(false);
      navigate(configData.routes.platforms.platforms);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onChange = async (e) => {
    const { name, value } = e?.target;

    if (name === "requiredMethods") {
      setOptions((op) => ({ ...op, requiredMethods: value }));
    } else {
      setPlatformDetails((state) => ({ ...state, [name]: value }));
    }
  };

  const fetchPlatformInfo = async () => {
    try {
      setLoading(true);
      const resp = await getPlatformInfo(id);
      setPlatformDetails(resp.data);
      if (resp && resp?.data) {
        const keys = Object.keys(resp.data);
        keys.forEach((v) => {
          if (
            defaultRequestMethods.includes(v) &&
            !options.requiredMethods.includes(v)
          ) {
            setOptions((state) => ({
              ...state,
              requiredMethods: [...state.requiredMethods, v],
            }));
          }
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  const onRequestChange = (e, request) => {
    const { name, value } = e.target;
    setPlatformDetails((state) => ({
      ...state,
      [request]: {
        ...state[request],
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    if (id !== "create") fetchPlatformInfo();
  }, []);

  return (
    <ContentShell
      showBackButton
      onBackButtonClick={cancel}
      title={`${id === "create" ? "Create" : "Edit"} Platform`}
      loading={loading}
      actions={
        <Box className={styles.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={submit}>
            Save
          </Button>
        </Box>
      }
    >
      <Box className={styles.container}>
        <Box className={styles.firstFormContainer}>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Platform Name"
              name="platformName"
              value={platformDetails.platformName}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleMultiSelectInput
              label="Select Required Methods"
              name="requiredMethods"
              value={options.requiredMethods}
              options={options.requiredMethodOptions}
              onChange={onChange}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={styles.secondFormContainer}>
          {options.requiredMethods?.map((request) => {
            let requestOption = options.requiredMethodOptions.find(
              (e) => e.id === request
            );
            return (
              <Box className={styles.requestContainer}>
                <Typography fontSize={12} fontWeight={700}>
                  {requestOption.label}
                </Typography>
                <Box className={styles.field}>
                  <SimpleTextInput
                    label={"URL"}
                    name={"url"}
                    value={platformDetails[request]?.url}
                    onChange={(e) => onRequestChange(e, request)}
                  />
                </Box>
                <Box className={styles.field}>
                  <SimpleSelectInput
                    name={"method"}
                    label={"Method Type"}
                    value={platformDetails[request]?.method}
                    options={options.httpMethodOptions}
                    onChange={(e) => onRequestChange(e, request)}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </ContentShell>
  );
};

export default PlatformEditor;

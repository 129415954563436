import { Client } from "./_clientService";

const executeRequest = async (options) => {
  try {
    const resp = await new Client(options)[options.method]();
    return resp;
  } catch (error) {
    throw error;
  }
};

async function create(params) {
  return executeRequest({
    path: "/brand/create",
    payload: params,
    method: "post",
  });
}

async function update(params, brandsId) {
  return executeRequest({
    path: `/brand/update/${brandsId}`,
    payload: params,
    method: "patch",
  });
}

async function getBrands(params) {
  let payload = {
    pageNo: params?.pageNo || 1,
    pageSize: params?.pageSize || 10,
    sortDirection: params?.sortDirection || -1,
    sortBy: params?.sortBy || "createdAt",
    search: params?.search ?? "",
  };

  if (params?.skipPagination) payload["skipPagination"] = true;

  const urlParams = new URLSearchParams(payload).toString();

  return executeRequest({
    path: `/brand/list?${urlParams}`,
    method: "get",
  });
}

async function getBrandInfo(brandsId) {
  if (!brandsId) throw new Error("No brands id provided");

  return executeRequest({
    path: `/brand/info/${brandsId}`,
    method: "get",
  });
}

export { create, update, getBrands, getBrandInfo };

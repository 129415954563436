/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Table from "components/Table";
import { clubTableColumns } from "utils/tableHelper/playerCardTableHelper";
import ContentShell from "components/ContentShell";
import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import configData from "config/config.json";
import { getClubs } from "services/playerCardService";
import usePaginationHook from "../../../hooks/usePagination";
import { opacity2hex } from "utils/miscUtils";
import SearchInput from "components/SearchInput";
import sessionHelper from "services/sessionHelper";
import { PermittedRoles } from "utils/permissions";
import { useSnackbar } from "hooks/useSnackbar";

const useStyles = makeStyles((theme) => ({
  savecancel: {
    width: "160px",
    height: 40,
  },
  editButton: {
    backgroundColor: theme.palette.common.offWhite + opacity2hex("0.80"),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  playerCardParent: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  searchInput: {
    width: "270px",
    marginBottom: 20,
  },
}));

const RowActions = ({ rowId, data }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const handleRowAction = (data) =>
    navigate(configData.routes.playerCard.editClub.replace(":id", data?._id));

  return (
    <div>
      <Button
        className={styles.editButton}
        onClick={() => handleRowAction(data)}
      >
        {" "}
        Edit{" "}
      </Button>
    </div>
  );
};

const defaultPageSize = 10,
  defaultPageNo = 1;

const ClubsList = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.CLUB.UPDATE_ROLES
  );

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({ defaultPageSize, defaultPageNo });

  const fetchData = async (v) => {
    try {
      setLoading(true);
      const response = await getClubs({
        pageNo,
        pageSize: pageSize,
        search: v,
      });
      const data = response.data.clubs.map((item) => {
        const cleanedItem = Object.keys(item).reduce((acc, key) => {
          acc[key] = item[key] ?? "-";
          return acc;
        }, {});
        return cleanedItem;
      });

      showSnackbar(response.message, "success");
      setFilteredList(data);
      setLoading(false);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
      console.error("Error fetching club data: ", error);
    }
  };

  const onSearch = (value) => {
    setSearchValue(value);
    onPageSizeChange({ target: { value: defaultPageSize } });
    fetchData(value);
  };

  const handleCreateClub = () => {
    navigate(configData.routes.playerCard.createClub);
  };

  useEffect(() => {
    const initialColumns = clubTableColumns().filter(
      (col) => col.id !== "actions" || hasEditPermission
    );
    setColumns(initialColumns);

    fetchData();
  }, [pageNo, pageSize, hasEditPermission]);

  return (
    <ContentShell
      loading={loading}
      title="Clubs"
      actions={
        <>
          <SearchInput
            value={searchValue}
            placeholder="Search a club"
            onSearch={onSearch}
          />
          {sessionHelper.hasRole(PermittedRoles.CLUB.CREATE_ROLES) && (
            <Button
              type="submit"
              variant="contained"
              className={styles.savecancel}
              onClick={handleCreateClub}
            >
              Create Club
            </Button>
          )}
        </>
      }
    >
      <Box className={styles.playerCardParent}>
        <Table
          columns={columns}
          data={filteredList}
          currentPage={pageNo}
          actions={RowActions}
          pageSize={pageSize}
          totalEntries={filteredList.length}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
          highlightAltRow={true}
        />
      </Box>
    </ContentShell>
  );
};

export default ClubsList;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { FaLock, FaHome, FaArrowLeft } from "react-icons/fa";
import { keyframes } from "@mui/system";
import configData from "config/config.json";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedLockIcon = styled(FaLock)(({ theme }) => ({
  fontSize: "6rem",
  color: theme.palette.error.main,
  animation: `${pulse} 2s infinite`,
  marginBottom: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: theme.shadows[4],
  },
}));

const AccessDenied = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = "Access Denied";
  }, []);

  const handleGoBack = () => {
    window.history.go(-2);
  };

  const handleGoHome = () => {
    navigate(configData.routes.dashboard);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: "background.default",
        py: 10,
      }}
    >
      <Container maxWidth="md">
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={3}
          role="main"
          aria-label="Access Denied Page"
        >
          <Grid item>
            <AnimatedLockIcon aria-hidden="true" />
          </Grid>
          <Grid item>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              component="h1"
              align="center"
              color="error"
              gutterBottom
              sx={{
                fontWeight: "bold",
                textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              Access Denied
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              paragraph
              sx={{ maxWidth: 600, mx: "auto" }}
            >
              We apologize, but you don't have permission to access this page.
              Please verify your credentials or contact the system administrator
              for assistance.
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Grid item>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<FaArrowLeft />}
                onClick={handleGoBack}
                aria-label="Go back to previous page"
              >
                Go Back
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                startIcon={<FaHome />}
                onClick={handleGoHome}
                aria-label="Go to homepage"
              >
                Home Page
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AccessDenied;
